import { Button, Grid, Paper, TextField, Typography } from '@mui/material'
import './signin.css'
import { useLocation,useNavigate } from 'react-router-dom'
import React, { useState } from 'react';
import {useSelector,useDispatch} from 'react-redux';
import { useChangePasswordMutation, useForgotPasswordMutation, useLoginMutation, useRegisterMutation, useVerifyForgotMutation, useVerifyUserMutation } from '../../slices/usersApiSlice';
import { setCredentials } from '../../slices/authSlice';
import {toast} from 'react-toastify';
import { useEffect } from 'react';
export default function Signin() {

  const [active,setActive]=useState('Signin')

  const [data,setData]=useState({})
const dispatch =useDispatch()
const navigate=useNavigate()

const [login,{isLoading:loading}]=useLoginMutation()
const [register,{isLoading:loading1}]=useRegisterMutation()
const [verifyUser,{isLoading:loading2}]=useVerifyUserMutation()
const [verifyForgot,{isLoading:loading3}]=useVerifyForgotMutation()
const [forgotPassword,{isLoading:loading4}]=useForgotPasswordMutation()
const [changePassword,{isLoading:loading5}]=useChangePasswordMutation()

// console.log(loading)
const {userInfo}=useSelector((state)=>state.auth)


useEffect(() => {

    if(userInfo){
     navigate('/')   
    }

}, [userInfo,navigate])

const signINClicked=async()=>{
    try{
        const res=await login({email:data.email.toLowerCase(),password:data.password}).unwrap()
        dispatch(setCredentials(res.data))
        toast.success(res.message)
        navigate('/')

    }catch(error){
toast.error(error?.data?.message||error.error)
    }

}
const signUpClicked=async()=>{

    try{
        const res=await register({email:data.email.toLowerCase(),password:data.password}).unwrap()
       setActive('Verify')
    }catch(error){
toast.error(error?.data?.message||error.error)
    }

    
}
const forgotPasswordClicked=async()=>{

    if(data.email){


        try{
            const res=await forgotPassword({email:data.email.toLowerCase()}).unwrap()
           setActive('Forgot Verify')
        }catch(error){
    toast.error(error?.data?.message||error.error)
        }
    }
    else{
        toast.error('Please provide email to continue')
    }


    
}

const forgotVerifyClicked=async()=>{
    try{
        const res=await verifyForgot({email:data.email.toLowerCase(),otp:data.otp}).unwrap()
        // dispatch(setCredentials(res.data))

        setActive('Forgot')
        // navigate('/')

    
    }catch(error){
toast.error(error?.data?.message||error.error)
    }
}
const changePasswordClicked=async()=>{
    if(data.password&&data.password1)
    
    
    {
        if(data.password===data.password1){
            try{
                const res=await changePassword({email:data.email.toLowerCase(),password:data.password}).unwrap()
               
                toast.success(res.message)
        
                setData({})
                setActive('Signin')
                // navigate('/signin')
        
            
            }catch(error){
        toast.error(error?.data?.message||error.error)
            }
        }
        else{
            toast.error('Password and Confirm Password should be same')  

        }

  
}
else{
    toast.error('Password and Confirm Password required')  
}

}
const verifyClicked=async()=>{
    try{
        const res=await verifyUser({email:data.email.toLowerCase(),otp:data.otp}).unwrap()
        dispatch(setCredentials(res.data))
        navigate('/')

    
    }catch(error){
toast.error(error?.data?.message||error.error)
    }
}

  return (
    <div className='bg_signin'>

<Paper style={{minheight:400,background:'var(--blueColor)',width:400,borderRadius:'5%'}}>
{active==='Signin'?
<Grid container  className='signin_cont'>
<Grid item sm={12} xs={12}>
{/* <div style={{height:60,width:60,backgroundColor:'white',margin:'1vh 0px'}}>

</div>   */}
        <img  src={require('../images/Icon.png')} onClick={()=>window.location.href='/'}  style={{height:65,backgroundColor:'white',margin:'1vh 0px'}} ></img>


  </Grid>
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'white',fontWeight:'600',fontSize:19,padding:'1vh 0px'}}></Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'white',fontWeight:'500',fontSize:16,padding:'3vh 10px 1vh 10px',textAlign:'start'}}>Email</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <TextField
            style={{background:'rgba(255,255,255,0.6)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold',margin:'1vh 0px'}}
        
        // label="TextField"
value={data.email}
        onChange={(e)=>setData({...data,email:e.target.value})}
        placeholder='Enter email'
        type='email'
        variant="standard"
      />

    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'white',fontWeight:'500',fontSize:16,padding:'1vh 10px',textAlign:'start'}}>Password</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <TextField
            style={{background:'rgba(255,255,255,0.6)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold',margin:'1vh 0px'}}
        // label="TextField"
        placeholder='Enter password'
        onChange={(e)=>setData({...data,password:e.target.value})}
        value={data.password}

        type='password'
        variant="standard"
      />

    </Grid>
    <Grid item sm={12} xs={12}>
<Typography onClick={()=>forgotPasswordClicked()}  style={{padding:'2px 1vw',fontSize:15,color:'white',textAlign:'start'}}><span style={{borderBottom:'1px solid white',cursor:'pointer'}}>Forgot Password?</span></Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
   <Button onClick={()=>signINClicked()}  style={{background:'white',color:'var(--blueColor)',padding:'5px 2vw',marginTop:'2vh',fontWeight:'600'}} >{loading||loading4?'Processing':' Sign in'}</Button>

    </Grid>
    <Grid item sm={12} xs={12}>
<Typography onClick={()=>setActive('Signup')} style={{padding:'2vh 0px',fontSize:17,color:'white'}}>Don't have an account? <b style={{borderBottom:'1px solid white',cursor:'pointer'}}>Sign up</b></Typography>
    </Grid>
</Grid>
:active==='Signup'?
<Grid  className='signin_cont' container>
<Grid item sm={12} xs={12}>
<img  src={require('../images/Icon.png')} onClick={()=>window.location.href='/'}  style={{height:65,backgroundColor:'white',margin:'1vh 0px'}} ></img>
  </Grid>

    <Grid item sm={12} xs={12}>
        <Typography style={{color:'white',fontWeight:'600',fontSize:20,padding:'1vh 0px'}}>Sign up</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
<Typography onClick={()=>setActive('Signin')} style={{fontSize:17,color:'white'}}>Already a member? <b style={{borderBottom:'1px solid white',cursor:'pointer'}}>Sign in</b></Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'white',fontWeight:'500',fontSize:16,padding:'3vh 10px 1vh 10px',textAlign:'start'}}>Email</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <TextField
            style={{background:'rgba(255,255,255,0.6)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold',margin:'1vh 0px'}}
        
        // label="TextField"
        onChange={(e)=>setData({...data,email:e.target.value})}
        value={data.email}

        placeholder='Enter email'
        type='email'
        variant="standard"
      />

    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'white',fontWeight:'500',fontSize:16,padding:'1vh 10px',textAlign:'start'}}>Password</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <TextField
            style={{background:'rgba(255,255,255,0.6)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold',margin:'1vh 0px'}}
        
        // label="TextField"
        placeholder='Enter password'
        onChange={(e)=>setData({...data,password:e.target.value})}
        value={data.password}

        type='password'
        variant="standard"
      />

    </Grid>
    <Grid item sm={12} xs={12}>
   <Button onClick={()=>signUpClicked()} style={{background:'white',color:'var(--blueColor)',padding:'5px 2vw',marginTop:'2vh',fontWeight:'600'}} >{loading1?'Processing':'Sign up'}</Button>

    </Grid>
    {/* <Grid item  sm={12} xs={12}>
                <Paper style={{background:'rgba(255,255,255,0.88)',boxShadow:'none',maxWidth:'90%',borderRadius:6,flexDirection:'row',display:'flex',alignItems:'center',margin:'1vw 0px'}}>
                <Typography style={{fontSize:20,width:'20%',textAlign:'left',paddingLeft:'1vw'}}>
                <i class="fab fa-linkedin-in"></i>
                </Typography> 
                <Typography style={{width:'80%',textAlign:'left',fontWeight:'700',fontSize:18,padding:10,color:'var(--blueColor)'}} >Continue with linkedin</Typography>
                </Paper>
            </Grid>
            <Grid item  sm={12} xs={12}>
                <Paper style={{background:'rgba(255,255,255,0.88)',boxShadow:'none',maxWidth:'90%',borderRadius:6,flexDirection:'row',display:'flex',alignItems:'center',margin:'1vw 0px'}}>
                <Typography style={{fontSize:20,width:'20%',textAlign:'left',paddingLeft:'1vw'}}>
                <i class="fab fa-google"></i>                </Typography> 
                <Typography style={{width:'80%',textAlign:'left',fontWeight:'700',fontSize:18,padding:10,color:'var(--blueColor)'}} >Continue with google</Typography>
                </Paper>
            </Grid>
            <Grid item  sm={12} xs={12}>
                <Paper style={{background:'rgba(255,255,255,0.88)',boxShadow:'none',maxWidth:'90%',borderRadius:6,flexDirection:'row',display:'flex',alignItems:'center',margin:'1vw 0px'}}>
                <Typography style={{fontSize:20,width:'20%',textAlign:'left',paddingLeft:'1vw'}}>
                <i class="fas fa-envelope"></i>                </Typography> 
                <Typography style={{width:'80%',textAlign:'left',fontWeight:'700',fontSize:18,padding:10,color:'var(--blueColor)'}} >Continue with email</Typography>
                </Paper>
            </Grid> */}
   
    
</Grid>
:active==='Forgot'?
<Grid  className='signin_cont' container>
<Grid item sm={12} xs={12}>
<img  src={require('../images/Icon.png')} onClick={()=>window.location.href='/'}  style={{height:65,backgroundColor:'white',margin:'1vh 0px'}} ></img>
    </Grid>

    <Grid item sm={12} xs={12}>
        <Typography style={{color:'white',fontWeight:'600',fontSize:20,padding:'1vh 0px'}}>Forgot Password</Typography>
    </Grid>
  
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'white',fontWeight:'500',fontSize:16,padding:'3vh 10px 1vh 10px',textAlign:'start'}}>Password</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <TextField
            style={{background:'rgba(255,255,255,0.6)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold',margin:'1vh 0px'}}
        
        // label="TextField"
        placeholder='Enter password'
        onChange={(e)=>setData({...data,password:e.target.value})}
        value={data.password}

        type='password'
        variant="standard"
      />
    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'white',fontWeight:'500',fontSize:16,padding:'1vh 10px',textAlign:'start'}}>Confirm Password</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <TextField
            style={{background:'rgba(255,255,255,0.6)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold',margin:'1vh 0px'}}
        
        // label="TextField"
        placeholder='Confirm password'
        onChange={(e)=>setData({...data,password1:e.target.value})}
        value={data.password1}

        type='password'
        variant="standard"
      />

    </Grid>
    <Grid item sm={12} xs={12}>
   <Button onClick={()=>changePasswordClicked()} style={{background:'white',color:'var(--blueColor)',padding:'5px 2vw',marginTop:'2vh',fontWeight:'600'}} >{loading5?"Processing":"Change Password"}</Button>

    </Grid>
   
   
    
</Grid>:active==='Forgot Verify'?
<Grid  className='signin_cont' container>
<Grid item sm={12} xs={12}>
<img  src={require('../images/Icon.png')} onClick={()=>window.location.href='/'}  style={{height:65,backgroundColor:'white',margin:'1vh 0px'}} ></img>
   </Grid>

<Grid item sm={12} xs={12}>
        <Typography style={{color:'white',fontWeight:'600',fontSize:20,padding:'1vh 0px'}}>Verify OTP</Typography>
    </Grid>
 
    <Grid item sm={12} xs={12}>
    <Typography style={{color:'white',fontWeight:'600',fontSize:15,padding:'1vh 0px'}}>Please enter otp sent to registered email</Typography>
    </Grid>
  
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'white',fontWeight:'500',fontSize:16,padding:'3vh 10px 1vh 10px',textAlign:'start'}}>OTP</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <TextField
            style={{background:'rgba(255,255,255,0.6)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold',margin:'1vh 0px'}}
        
        // label="TextField"
        placeholder='Enter otp'
        onChange={(e)=>setData({...data,otp:e.target.value})}
        value={data.otp}

        type={'number'}
        variant="standard"
      />
    </Grid>
   
    <Grid item sm={12} xs={12}>
   <Button onClick={()=>forgotVerifyClicked()} style={{background:'white',color:'var(--blueColor)',padding:'5px 2vw',marginTop:'2vh',fontWeight:'600'}} >{loading3?"Processing":"Verify otp"}</Button>

    </Grid>
   
   
    
</Grid>
:
<Grid  className='signin_cont' container>
<Grid item sm={12} xs={12}>
<img  src={require('../images/Icon.png')} onClick={()=>window.location.href='/'}  style={{height:65,backgroundColor:'white',margin:'1vh 0px'}} ></img>
   </Grid>
<Grid item sm={12} xs={12}>
        <Typography style={{color:'white',fontWeight:'600',fontSize:20,padding:'1vh 0px'}}>Verify OTP</Typography>
    </Grid>
 
    <Grid item sm={12} xs={12}>
    <Typography style={{color:'white',fontWeight:'600',fontSize:15,padding:'1vh 0px'}}>Please enter otp sent to registered email</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'white',fontWeight:'500',fontSize:16,padding:'3vh 10px 1vh 10px',textAlign:'start'}}>OTP</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <TextField
            style={{background:'rgba(255,255,255,0.6)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold',margin:'1vh 0px'}}
        
        // label="TextField"
        placeholder='Enter otp'
        onChange={(e)=>setData({...data,otp:e.target.value})}
        value={data.otp}

        type={'number'}
        variant="standard"
      />
    </Grid>
   
    <Grid item sm={12} xs={12}>
   <Button onClick={()=>verifyClicked()} style={{background:'white',color:'var(--blueColor)',padding:'5px 2vw',marginTop:'2vh',fontWeight:'600'}} >{loading2?'Processing':'Verify otp'}</Button>

    </Grid>
   
   
    
</Grid>}


</Paper>

    </div>
  )
}
