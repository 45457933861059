import { EVENTS_URL, UPLOAD_URL, USERS_URL } from "../constants";
import { apiSlice } from "./apiSlice";
export const eventApiSlice=apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        createEvent:builder.mutation({
            query:(data)=>({
                url:`${EVENTS_URL}/event`,
                method:'POST',
                body:data

            })

        }),

        fetchParticularEvent:builder.query({
            query:(id)=>({
                url:`${EVENTS_URL}/all/${id}`


            }),
            keepUnusedDataFor:5,

        }),
        fetchAllEvents:builder.query({
            query:(id)=>({
                url:`${EVENTS_URL}/event`


            }),
            keepUnusedDataFor:5,

        }),
        fetchUsersEvent:builder.query({
            query:(id)=>(
                id?
                {
                url:`${EVENTS_URL}/event/${id}`,
              

            }:null)

        }),
        editEvent:builder.mutation({
            query:(data)=>({
                url:`${EVENTS_URL}/event/${data._id}`,
                method:'PUT',
                body:data

            })

        }),

        fetchLocation:builder.query({
            query:()=>({
                url:`https://ipapi.co/json/`,
              

            }),
            keepUnusedDataFor:5,


        }),
    
})})

export const {useCreateEventMutation,useFetchParticularEventQuery,useFetchAllEventsQuery,useFetchUsersEventQuery,useEditEventMutation,useFetchLocationQuery}=eventApiSlice