import React from 'react'
import People_top from './pople_top'
import People_below from './people_below'

export default function People() {
  return (
    <div>
        {/* <People_top /> */}
        <People_below />
    </div>
  )
}
