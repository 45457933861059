import React, { useEffect, useState } from 'react'
// import './p_people.css'
import { useParams } from 'react-router-dom';
import { Avatar, Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, Container, Grid, InputAdornment, Paper, Tab, Tabs, TextField, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEditPostMutation, useFetchParticularPostQuery } from '../../slices/postSlice';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CheckCircle } from '@mui/icons-material';
import { useEditProfileMutation } from '../../slices/usersApiSlice';
export default function Post() {

   let {id}=useParams() 
   const {userInfo}=useSelector((state)=>state.auth)

   const [value,setValue]=useState(0)
   const arr=[1,2,3,4,5,6,1,2,3,4,5,6]
   const {data:post,isLoading,isError,refetch}=useFetchParticularPostQuery(id);
   const [editPost,{isLoading:loadingPostE}]=useEditPostMutation()
   const [editProfile,{}]=useEditProfileMutation()

   const [comment,setComment]=useState('')
   const [comment_text,setCommentText]=useState('')

   useEffect(() => {
    if(isError)   
         window.location.href='/people'
       }, [isLoading]) 
      
        
        const postComment=async()=>{
        
        
          if(comment_text){
        if(userInfo){
          let comment1={
            _id:post._id,
            comment:
           { 
            id:userInfo._id,
            comment:comment_text
          }
          }
        
        try {
          
        const res=await editPost(comment1)
        if(res){
        
          setCommentText('')
          refetch()
        }
        
        } catch (error) {
           toast.error(error?.data?.message||error.error)
        
          
        }}
        else{
          toast.error('Please sign in to continue ..')
        }
        
          }
        }    
        const Connect=async(val)=>{
          if(userInfo){
            let connection={
              _id:val._id,
              connection:
             { 
              id:userInfo._id}
            }
          
          try {
            
          const res=await editProfile(connection)
          if(res){
            refetch()
           
          }
          
          } catch (error) {
             toast.error(error?.data?.message||error.error)
          
            
          }}
          else{
            toast.error('Please sign in to continue ..')
          }
            // refetch()
           }
       return (

    <div style={{width:'100%',background:'#eee',minHeight:'110vh'}}>
    <Container style={{paddingTop:110}}>
        {
    post&&!isLoading?<Grid container alignItems={'start'}>
            <Grid container style={{marginBottom:'5vh'}} sm={8} xs={12}>
                <Paper style={{minHeight:'40vh',width:'100%',borderRadius:15}}>
                
<Grid container style={{padding:'2vh 1.5rem'}}>
<div style={{width:'100%'}} className='content_people'>
{post.creator.profileImage?<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} src={post.creator.profileImage} ></Avatar>:<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} >{post.creator.fname&&post.creator.lname?(post.creator.fname.charAt(0).toUpperCase()+post.creator.lname.charAt(0).toUpperCase()):post.creator.email.charAt(0).toUpperCase()}</Avatar>}
 

<div style={{padding:'0px 1vw',width:'40%'}}>
<Typography variant="h6" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)'}} component="div">
 {post.creator.fname&&post.creator.lname?(post.creator.fname+' '+post.creator.lname):post.creator.email}
 </Typography>
 {post.creator.designation?<Typography style={{margin:'2vh 0px'}} variant="h7" sx={{ mb: 1.5 }} >
 {post.creator.designation}<b> | </b> {post.creator.company}
 </Typography>:null}
 <Typography style={{color:'var(--blueColor)',padding:'10px 0px'}} variant="body2">
     <span><i class="fas fa-calendar"></i></span> {dayjs(post.createdAt).format('LL')} · {dayjs(post.createdAt).format('LT')}
        <br />
      </Typography>




</div>
<div style={{width:'50%',textAlign:'right'}}>
{userInfo&&post.creator._id!==userInfo._id?


(
  post.creator.connections&&post.creator.connections[0]&&post.creator.connections.find(val=>val.user===userInfo._id)?
<Chip
className='chip_main'
label="Connected"


onDelete={()=>console.log('')}
deleteIcon={<CheckCircle style={{color:'var(--blueColor)'}} />}
/>:
<Chip
className='chip_main'
label="Connect"

onClick={()=>Connect(post.creator)}
onDelete={()=>Connect(post.creator)}
deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>)
:
userInfo&&post.creator._id===userInfo._id?null
:<Chip
className='chip_main'
label="Connect"
onClick={()=>Connect(post.creator)}
onDelete={()=>Connect(post.creator)}
deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>
}
</div>
 </div>
 <Grid item sm={12} xs={12}>
 { post.postImage?<img src={post.postImage} style={{height:'50vh',width:'100%',objectFit:'contain'}} />
:null}
<Typography className='postData' dangerouslySetInnerHTML={{__html:post.post}} style={{padding:10,textAlign:'justify',lineHeight:1.8}} >

</Typography>

</Grid>
<Grid item textAlign={'start'} style={{padding:'1vh 0px',flexDirection:'row',display:'flex'}} sm={12} xs={12}>
  
  
  <TextField value={comment_text} onChange={(e)=>setCommentText(e.target.value)} placeholder='Post your comment here ..' variant={'standard'} multiline   style={{width:'80%',background:'#eee',borderRadius:20,borderColor:'white',padding:10,marginRight:10}} />
  <Chip  onClick={()=>postComment()} label={ loadingPostE?"Posting":'Post Comment'} className='chip_main'  />
  
  </Grid>
  {post.comments&&post.comments[0]?<Grid item textAlign={'start'} style={{maxHeight:'45vh',overflow:'scroll'}} sm={12} xs={12}>
  
  
{post.comments.map((val,idx)=>
  <div key={idx} style={{padding:'2vh 0px',maxWidth:'93%'}} className='content_people'>
{val.user.profileImage?<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} src={val.user.profileImage} ></Avatar>:<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} >{val.user.fname&&val.user.lname?(val.user.fname.charAt(0).toUpperCase()+val.user.lname.charAt(0).toUpperCase()):val.user.email.charAt(0).toUpperCase()}</Avatar>}
 

<div style={{padding:'1vh 1vw',borderRadius:'0px 20px 20px 20px',background:'#eee',marginLeft:10}}>
<Typography variant="h7" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)'}} component="div">
{val.user.fname&&val.user.lname?(val.user.fname+' '+val.user.lname):val.user.email}
  </Typography>
  <Typography color={'text.secondary'} style={{margin:'1vh 0px'}} variant="h8" sx={{ mb: 1.5 }} >
  {val.comment}
  </Typography>

</div>

 </div>)    }
  </Grid>:null}
</Grid>



                </Paper>
              
            
            </Grid>
            <Grid container style={{padding:'0px 20px'}} sm={4} xs={12}>
            <Paper style={{padding:'0px 10px',background:'rgba(5, 17, 33, 0.79)',boxShadow:'none',borderRadius:7,width:'100%',}}>
            
            <Grid item sm={12} xs={12}>

</Grid>
            

<Grid 
item sm={12} xs={12}>
<Card  style={{margin:15}} sx={{ minWidth: 250 }}>
<CardContent>
<div className='content_people'>
{post.creator.profileImage?<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} src={post.creator.profileImage} ></Avatar>:<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} >{post.creator.fname&&post.creator.lname?(post.creator.fname.charAt(0).toUpperCase()+post.creator.lname.charAt(0).toUpperCase()):post.creator.email.charAt(0).toUpperCase()}</Avatar>}
 

<div style={{padding:'0px 1vw'}}>
<Typography variant="h6" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)'}} component="div">
{post.creator.fname&&post.creator.lname?(post.creator.fname+' '+post.creator.lname):post.creator.email}
  </Typography>
  {post.creator.designation?<Typography style={{margin:'2vh 0px'}} variant="h7" sx={{ mb: 1.5 }} >
 {post.creator.designation}<b> | </b> {post.creator.company}
 </Typography>:null}
  <div >
<Chip

 onClick={()=>window.location.href=`/people/${post.creator._id}`}
className='chip_main'
label="View full profile"
// onDelete={()=>console.log('')}
// deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>
</div>
</div>

 </div>
  {/* <br /><br /> */}

  {/* <Typography style={{margin:'2vh 0px'}} variant="h7" sx={{ mb: 1.5 }} color="text.secondary">
  Hello Everyone💕
We're happy to invite you to the ✨"Nav Grah Awakening" . 
  </Typography> */}

</CardContent>

</Card>
</Grid>
    </Paper>
            </Grid>
        </Grid>:<Box sx={{ display: 'flex' ,justifyContent:'center',marginTop:'20vh'}}>
      <CircularProgress  style={{height:50,width:50}}/>
    </Box>}
    </Container>
    </div>
  )
}
