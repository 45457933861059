
import React,{useState,useRef,useEffect, useLayoutEffect} from "react"
import { Grid,TextField,Typography,Paper,Button,Radio,RadioGroup,
    FormControlLabel,FormControl,FormLabel, Chip, Avatar, Box, CircularProgress, InputAdornment, IconButton, InputLabel } from "@mui/material"
import './chat.css'
import { useSelector } from "react-redux"
import { useFetchProfileQuery } from "../../../slices/usersApiSlice"
import { Send } from "@mui/icons-material"
import { socket } from "../../../constants"
import { toast } from "react-toastify"
export default function Chat(){
  const {userInfo}=useSelector((state)=>state.auth)
  const {data:user,isLoading:loadingUser,isError:errorUser,refetch:refetchUsers}=useFetchProfileQuery(userInfo?._id);
const [msg,setMsg]=useState('')

const [messages,setMessages]=useState([])


// console.log(messages)
    const [data,setData]=useState(false)


    const arr=[1,2,3,4,5,6,1,2,3,4,5,6]




// console.log(user&&user.connections&&user.connections.find(val=>val.user===userInfo._id))
   const [chat1,setChat]=useState('')

  //  console.log(chat1?(user.connections.find(val=>val.user._id===chat1._id).connection_id):'not' )




    useEffect(()=>{
      if(myRef&&myRef.current)
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
      
    },[])
useLayoutEffect(()=>{

      if(chat1&&chat1._id)

  socket.emit("findRoom",user.connections.find(val=>val.user._id===chat1._id).connection_id);
  socket.on("foundRoom", (roomChats) => setMessages(roomChats));

},[chat1,socket])
   
    

  
    const Data=()=>{
        if(data){
          setChat('')
          setData(!data)
        
        }
        else{
          setChat('')

          setData(!data)
         
        }
      }


    
      const myRef = useRef(null)



   
const executeScroll=()=>{

    if(myRef&&myRef.current)
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  

}




const Chat=async(val)=>{
setChat(val)

  await socket.emit('joinRoom', {room:user.connections.find(val1=>val1.user._id===val._id).connection_id});

}

const handleMessage = () => {

  if(msg?.length>0)
{  const hour =
      new Date().getHours() < 10
          ? `0${new Date().getHours()}`
          : `${new Date().getHours()}`;

  const mins =
      new Date().getMinutes() < 10
          ? `0${new Date().getMinutes()}`
          : `${new Date().getMinutes()}`;


  socket.emit("newMessage", {
              message:msg,
              room_id: user.connections.find(val=>val.user._id===chat1._id).connection_id ,
              user_id:userInfo._id,
              timestamp: { hour, mins }})
setMsg('')}
else{
  toast.error('Please provide msg to send')
}
};

    return (
        <div>
      
      
        <Grid container className='topcont'>
         
      
          <Grid item alignSelf='center' style={{paddingBottom:'2px'}} sm={12} xs={12}>
          {data?<div   className='container1'   >
<div>
  <Grid container>
<Grid style={{textAlign:'start'}} item sm={12} xs={12}>
{/* <Paper className="welcome_container" elevation={2} >
  <Typography className="welcome_msg" >Welcome to Dec6, please select any service to continue</Typography>
  
  </Paper> */}
{!chat1?<Typography style={{padding:'1vh 0px',textAlign:'center',fontSize:22,fontWeight:'700',position:'absolute',zIndex:1,background:'white',width:348,borderTopLeftRadius:20,borderTopRightRadius:20}}>
  Chat with connections
</Typography>:<Typography style={{padding:'1vh 10px',textAlign:'left',fontSize:22,fontWeight:'700',position:'absolute',zIndex:1,background:'white',width:330,borderTopLeftRadius:20,borderTopRightRadius:20}}>
<i  style={{paddingRight:50,cursor:'pointer'}}  onClick={()=>setChat('')} class="fas fa-arrow-circle-left"></i> {chat1.fname+' '+chat1.lname}
</Typography>
}
{
!chat1?(
userInfo&&!loadingUser?(

user&&user.connections&&user.connections[0]?
(<div style={{marginTop:'5vh',cursor:'pointer'}}>
{user.connections.map((val,idx)=>
  <div className='content_people' onClick={()=>Chat(val.user)} style={{border:'1px solid var(--blueColor)',padding:'2vh'}} key={idx}>
 {val.user.profileImage?<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} src={val.user.profileImage} ></Avatar>:<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} >{val.user.fname&&val.user.lname?(val.user.fname.charAt(0).toUpperCase()+val.user.lname.charAt(0).toUpperCase()):val.user.email.charAt(0).toUpperCase()}</Avatar>}
 {/* <Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} >NS</Avatar> */}

<div style={{padding:'0px 1vw'}}>
<Typography variant="h6" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)',wordBreak:'break-word'}} component="div">
{val.user.fname&&val.user.lname?(val.user.fname+' '+val.user.lname):val.user.email}
  </Typography>


   {val.user.designation?<Typography style={{margin:'2vh 0px'}} variant="h7" sx={{ mb: 1.5 }} >
     {val.user.designation}<b> | </b> {val.user.company}
     </Typography>:null} 
  <div >
<Chip
className='chip_main'
label="Message"
// onDelete={()=>console.log('')}
// deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>
</div>
</div>

 </div>
)}


</div>): <div className='content_people'>
 

 <div style={{padding:'0px 1vw'}}>
 <Typography variant="h6" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)'}} component="div">
   No connections found
   </Typography>
 
 </div>
 
  </div>):loadingUser?<Box sx={{ display: 'flex' ,justifyContent:'center',marginTop:'10vh',width:'100%'}}>
    <CircularProgress  style={{height:40,width:40,color:'var(--blueColor)'}}/>
  </Box>:
<div className='content_people' style={{marginTop:'10vh',justifyContent:'center'}} >
 


 <Chip
 onClick={()=>window.location.href='/signin'}
 className='chip_main'
 label="Signin To Continue"
 // onDelete={()=>console.log('')}
 // deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
 />
 </div>):
 <div style={{height:'100%'}}>
  <div style={{height:430,overflow:'scroll'}}>
 {messages&&messages[0]?(messages.map((val,idx)=>

<div>
{ val.creator===userInfo._id?

 (<Grid style={{marginTop:idx===0?55:0}} item textAlign={'-webkit-right'} sm={12} xs={12}>
  <Paper  className="category_container" elevation={2} >
  <Typography  className="category_msg" >{val.msg}</Typography>
  
  </Paper> 
  </Grid>):(<Paper className="welcome_container" elevation={2} >
 <Typography style={{marginTop:idx===0?55:0}} className="welcome_msg" >{val.msg}</Typography>
 
 </Paper> )}
 </div>
 )):null}
 

   </div>
   <Grid item textAlign={'-webkit-center'} sm={12} xs={12}>
{/* <TextField multiline    endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>console.log('1')}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            } style={{width:'90%'}}  /> */}
  <TextField
        id="input-with-icon-textfield"
        multiline
value={msg}
        onChange={(e)=>setMsg(e.target.value)}
        style={{width:'90%',background:'#eee',padding:10,borderRadius:20}}
        // label="TextField"
        InputProps={{
          endAdornment: (
            <InputAdornment onClick={()=>handleMessage()} position="end">
              <Send  style={{color:'var(--blueColor)',cursor:'pointer'}} />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
</Grid>
   </div>
 
 }
</Grid>








  </Grid>
  
  
  </div>
</div>:null}
      
          </Grid>
          <Grid item sm={12} xs={12} onClick={()=>Data()} className='button1'     style={{alignSelf:data?'flex-start':'flex-end'}}>
            <div  style={{paddingTop:'6.5px'}}>{data?<i  className="fas fa-times iconM"></i>:<i  className="fas fa-comment iconM2"></i>}</div>
            
          </Grid>
          </Grid>
          </div>
      )
}