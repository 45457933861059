import React, { useState } from 'react';
import {NavLink} from "react-router-dom";

import Grid from '@mui/material/Grid';
import './header.css'
import { useSelector } from 'react-redux';
import { useLogoutUserMutation } from '../../../slices/usersApiSlice';

function Navbar() {
  const [navbarA,setNavbar] = useState(false);
  const {userInfo}=useSelector((state)=>state.auth)
const [logoutUser,{isLoading}]=useLogoutUserMutation()
  const changeBackground = () => {
    if(window.scrollY >= 100)
    {
        setNavbar(true);
    }
    else {
        setNavbar(false);
    }
};
window.addEventListener('scroll',changeBackground);
      

const signoutPressed=async()=>{

  const res =await logoutUser().unwrap()
  if (res){
    localStorage.removeItem('userInfo')
    window.location.reload()
  }

}
return(
            <nav>
            <div style={{width:'100%',justifyItems:'center',display:'grid'}}>
            <div className={navbarA?'Navbar_ItemsA':'Navbar_Items'}>

<Grid justifyContent={'flex-end'} display={'flex'} alignItems={'center'} container style={{paddingRight:'4rem'}}  className='gird_nav'>

<Grid container sm={3} xs={12}>
{/* <div style={{height:50,width:50,borderRadius:'50%',backgroundColor:'white',marginLeft:'2vw'}}> */}
<img  src={require('../../images/Icon.png')} onClick={()=>window.location.href='/'}  style={{height:60,marginLeft:'2vw'}} ></img>

{/* </div> */}
</Grid>
<Grid container justifyContent={'flex-end'} sm={9} xs={12}>
<Grid item>
<NavLink className={({ isActive }) => isActive ? "Navbar_Link2" : "Navbar_Link1"}  to="/"   >
                              HOME
                            </NavLink>
</Grid>
<Grid item>
<NavLink className={({ isActive }) => isActive ? "Navbar_Link2" : "Navbar_Link1"}  to="/people"   >
                              PEOPLE
                            </NavLink>
</Grid>
{/* 
<Grid item>
<NavLink className={({ isActive }) => isActive ? "Navbar_Link2" : "Navbar_Link1"}  to="/events"   >
                             EVENTS
                            </NavLink>
</Grid> */}


<NavLink className={({ isActive }) => isActive ? "Navbar_Link2" : "Navbar_Link1"}  to="/looking-for-funds"   >
NEED FUNDS
                            </NavLink>

                            <Grid item>
<NavLink className={({ isActive }) => isActive ? "Navbar_Link2" : "Navbar_Link1"}  to="/be-an-investor"   >
BE AN INVESTOR
                            </NavLink>
</Grid>
<Grid item>
<NavLink className={({ isActive }) => isActive ? "Navbar_Link2" : "Navbar_Link1"}  to="/help"   >
HELP
                            </NavLink>
                           
</Grid>
{userInfo?null:<Grid item>
<NavLink className={({ isActive }) => isActive ? "Navbar_Link2" : "Navbar_Link1"}  to="/signin"   >
                              SIGN IN
                            </NavLink>
</Grid>}
{userInfo?<Grid item  className='Navbar_Link1' onClick={()=>signoutPressed()} style={{color:'white'}}>
SIGNOUT                
</Grid>:null}



<Grid item>
{userInfo?<NavLink className={"Navbar_Link1"}  to="profile"   >
<i style={{fontSize:25}} class="fas fa-user-circle"></i>       
                    </NavLink>:null}

</Grid>


</Grid>
    </Grid>                  
            </div>


            </div>





            </nav>
        );
    }
    
    export default Navbar;