

import { io } from "socket.io-client";

export const BASE_URL=process.env.NODE_ENV==='development'?'http://localhost:8000':''
export const USERS_URL='/api/users';
export const UPLOAD_URL='/api/upload';
export const EVENTS_URL='/api/events';
export const POSTS_URL='/api/posts';

export const socket = io.connect("https://thefounderworld.com");
