import React from 'react'
import Home_top from './home_top'
import Home_below_top from './home_below_top'

export default function Home() {
  return (
    <>
<Home_top/>
<Home_below_top />
</>
  )
}
