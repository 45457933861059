import React, { useEffect, useState } from 'react'
import './pople_top.css'
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import SearchIcon from '@mui/icons-material/Search';
import { Checkbox, Chip, Container, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, Paper, Radio, RadioGroup, Slide, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import { Close } from '@mui/icons-material';
import { MyEditorS } from './editor';
import { useCreateEventMutation, useFetchAllEventsQuery, useFetchLocationQuery } from '../../slices/eventSlice';
import { useFetchAllProfilesQuery, useUploadImageMutation } from '../../slices/usersApiSlice';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {Navigate} from 'react-router-dom'
import { useCreatePostMutation, useFetchAllPostsQuery } from '../../slices/postSlice';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function People_top(props) {


  const {active}=props
  // console.log(active)
  const [value, setValue] = React.useState(dayjs(new Date()));
  const {userInfo}=useSelector((state)=>state.auth)
const [createPost,{isLoading:loadingPost}]=useCreatePostMutation()
const [end_timings,setEndTimings]=useState(true)
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);
    const{ data:location,isLoading:loadingLocation}=useFetchLocationQuery()




    const [open, setOpen] = React.useState(false);
const [tag,setTag]=useState('')
const [tags,setTags]=useState([])
   const [data,setData]=useState({creator:userInfo?._id}) 
const [post,setPost]=useState('')
const[createEvent,{isLoading:loading}]=useCreateEventMutation()

const [uploadImage,{isLoading:loadingImage}]=useUploadImageMutation()

// const {data:posts,isLoading:loadingPosts,isError:errorPost}=useFetchAllPostsQuery()
// const {data:events,isLoading:loadingEvents,isError:errorEvent}=useFetchAllEventsQuery()
// const {data:users,isLoading:loadingUsers,isError:errorUser}=useFetchAllProfilesQuery()


// console.log(posts,eve)
const [eventT,setEvent]=useState({creator:userInfo?._id})
   const addTags=()=>{
    if(tag){

      setEvent({
        ...eventT,tags:[...tags,'#'+tag]
      })
      setTags([...tags,'#'+tag])
    }
   }
const [editor,setEditor]=useState(true)
  // console.log(tags)
    const handleClose = () => {
      setPost('')
      setOpen(false);
    };
  
    // console.log(data)
    const quillChange=(val)=>{

      // console.log(val)
      setData(data => ({ ...data, post:val}))
      
     }
   
     const handleImage=async(val)=>{
// console.log(val)
      const formdata = new FormData()
      formdata.append('image',val)
      // console.log(val)
    
     
      setEvent({
        ...eventT, eventImage:val
      }) 
    
    
      try {
        
        const res=await uploadImage(formdata).unwrap()
    
        if(res){
          toast.success(res.message)
          setEvent({
            ...eventT,eventImage:res.image
          })  
    
           }
    
      } catch (error) {
        toast.error(error?.data?.message||error.error)
    
      }
    
    
    
    }
      const handleImage1=async(val)=>{
// console.log(val)
      const formdata = new FormData()
      formdata.append('image',val)
      // console.log(val)
    
     
      setData({
        ...data, postImage:val
      }) 
    
    
      try {
        
        const res=await uploadImage(formdata).unwrap()
    
        if(res){
          toast.success(res.message)
          setData({
            ...data,postImage:res.image
          })  
    
           }
    
      } catch (error) {
        toast.error(error?.data?.message||error.error)
    
      }
    
    
    
    }
const Open=()=>{
  if(userInfo){
    setOpen(true)
  }
  else{
    // Navigate('/signin')
window.location.href='/signin'  }
}

// console.log(eventT)
const Event=async()=>{
  if(eventT.eventImage&&eventT.eventType&&eventT.name&&eventT.startDate&&eventT.startTime&&eventT.description){
   try {
      const res= await createEvent(eventT).unwrap()
      if(res){
        // console.log(res)
  
        toast.success(res.message)
        handleClose()
            window.location.reload();
  
      }
      else{
  
        toast.error('Error occured while creating event')
  
      }
      
    } catch (error) {
      toast.error(error?.data?.message||error.error)
    }
  }
  
  else{
    toast.error('All (*) fields are mandatory')
  }
   
  
  }
  const Post=async()=>{
    if(data.post){
      try {
        const res= await createPost(data).unwrap()
        if(res){
          // console.log(res)
    
          toast.success(res.message)
          handleClose()
              window.location.reload();
    
        }
        else{
    
          toast.error('Error occured while creating event')
    
        }
        
      } catch (error) {
        toast.error(error?.data?.message||error.error)
      }
    }
    else{
      toast.error('All fields are mandatory')
    }
     
    }
  return (
    <div className='bg_people'>

<Container  style={{padding:'5vh 0px',height:'100%'}}>
    <Grid container style={{height:'100%'}} >


    <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {!post?<DialogTitle style={{fontSize:22,fontWeight:'bold',color:'var(--blueColor)'}}>{"Create Event / Post"}</DialogTitle>
        :<DialogTitle style={{fontSize:22,fontWeight:'bold',color:'var(--blueColor)'}}>{`Create ${post}`}</DialogTitle>}
 <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        {!post?<DialogContent>
          <div    onClick={()=>setPost('Event')} className='click_event'>
Create event
          </div>
          <div  onClick={()=>setPost('Post')} className='click_post'>
          Create post

            </div>
        </DialogContent>
:post==='Event'?
 <DialogContent>
          <div className='bg_img_create'>
          {/* <label>
  <input type="file" class="hidden" name="file1">
  <img class="h-6 w-6" alt="Image here" src="/images/download1.svg">
</label> */}

<label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>handleImage(e.target.files[0])}  name='file1'/>

               {eventT?.eventImage? <img    htmlFor='file1'  src={eventT.eventImage&&eventT.eventImage.name?URL.createObjectURL(eventT.eventImage):eventT.eventImage} style={{width:'100%',height:'22vh',objectFit:'contain',cursor:'pointer'}} />
:
          <i  htmlFor='file1'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'var(--blueColor)',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>

          {/* <input  type='file' onChange={(e)=>console.log(e.target.files[0])} /> */}
         {!eventT.eventImage? <div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'var(--blueColor)'}}>Upload Event Image*</h7>
          </div>:null}
            </div>

            <div style={{padding:'1vh 0px'}}>
            <h7 style={{width:'100%',fontSize:18,fontWeight:'600',color:'var(--blueColor)'}}>Event Type*</h7>
<div style={{}}>
<FormControl>
      <RadioGroup 
      style={{flexDirection:'row'}}
        // defaultValue="female"
        name="controlled-radio-buttons-group"
        value={eventT.eventType}
        onChange={(e)=>setEvent({...eventT,eventType:e.target.value})}
        sx={{ my: 1 }}
      >

        <div style={{display:'flex',flexDirection:'row',alignItems:"center",paddingRight:'3vw'}}>
<Typography style={{fontSize:'16px',fontWeight:'600'}}>
  Online
</Typography>

        <Radio value="Online" label="Online"   />
        </div>
        <div style={{display:'flex',flexDirection:'row',alignItems:"center"}}>
<Typography style={{fontSize:'16px',fontWeight:'600'}}>
  In Person
</Typography>

        <Radio value="In Person" label="In Person"   />
        </div>      </RadioGroup>
    </FormControl>
</div>
            </div>
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              <TextField  style={{width:'100%'}}  variant={'outlined'} value={eventT.name}
        onChange={(e)=>setEvent({...eventT,name:e.target.value})}
         label='Event Name*' placeholder='Enter event name' />
            </Grid>
            <Grid container style={{padding:'1vh 0px'}} sm={12} xs={12}>
            <Grid container style={{paddingRight:10}} sm={6} xs={12}>
            <LocalizationProvider  dateAdapter={AdapterDayjs}>
        <DatePicker 
          label="Start Date*"
          value={eventT.startDate?eventT.startDate:value}
          onChange={(val)=>setEvent({...eventT,startDate:new Date(val.$d)})}
        />
    </LocalizationProvider>  
              </Grid>
            <Grid container  sm={6} xs={12}>
            <LocalizationProvider  dateAdapter={AdapterDayjs}>

            <TimePicker
  label="Start Time*"
  
  value={eventT.startTime?eventT.startTime:value}
  onChange={(val)=>setEvent({...eventT,startTime:new Date(val.$d)})}

/>          
</LocalizationProvider>
  </Grid>      
  
        </Grid>

        <FormGroup style={{padding:'1vh 0px'}}>
  <FormControlLabel onChange={(e)=>setEndTimings(e.target.checked)} control={<Checkbox checked={end_timings} />} label="Add end date & time" />
  </FormGroup>
 {end_timings? 
 <Grid container style={{padding:'1vh 0px'}} sm={12} xs={12}>
            <Grid container style={{paddingRight:10}} sm={6} xs={12}>
            <LocalizationProvider  dateAdapter={AdapterDayjs}>
        <DatePicker 
          label="End Date"
          value={eventT.endDate?eventT.endDate:value}
          onChange={(val)=>setEvent({...eventT,endDate:new Date(val.$d)})}
        
        />
    </LocalizationProvider>  
              </Grid>
            <Grid container  sm={6} xs={12}>
            <LocalizationProvider  dateAdapter={AdapterDayjs}>

            <TimePicker
  label="End Time"
  value={eventT.endTime?eventT.endTime:value}
  onChange={(val)=>setEvent({...eventT,endTime:new Date(val.$d)})}

/>          
</LocalizationProvider>
  </Grid>      
  
        </Grid>:null}
        {eventT.eventType==='In Person'?<>   <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              <TextField  style={{width:'100%'}} value={eventT.address}
        onChange={(e)=>setEvent({...eventT,address:e.target.value})}  variant={'outlined'} label='Address' placeholder='Enter event address' />
            </Grid>
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              <TextField  style={{width:'100%'}} variant={'outlined'} label='Venue' value={eventT.venue}
        onChange={(e)=>setEvent({...eventT,venue:e.target.value})}  placeholder='Enter event venue' />
            </Grid></>:null}
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              <TextField  style={{width:'100%'}} value={eventT.description}
        onChange={(e)=>setEvent({...eventT,description:e.target.value})}  variant={'outlined'} label='Description*' placeholder='Enter event description' />
            </Grid>
        <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              <TextField  style={{width:'100%'}}value={eventT.externalLink}
        onChange={(e)=>setEvent({...eventT,externalLink:e.target.value})}  variant={'outlined'} label='External Event Link' placeholder='Enter external event link' />
            </Grid>
         

            <Grid container alignItems={'center'} style={{padding:'1vh 0px'}} sm={12} xs={12}>
            <Grid container style={{paddingRight:10}} sm={9} xs={9}>
            <TextField  onChange={(e)=>setTag(e.target.value)} style={{width:'100%'}} variant={'outlined'} label='Event Tags' placeholder='Enter event tags' />

              </Grid>
            <Grid container  sm={3} xs={3}>
         <Chip   label='Add Tags' onClick={()=>addTags()}  className='chip_secondary'/>
  </Grid>      
  
        </Grid>

        <Grid style={{flexWrap:'wrap'}} container  sm={12} xs={12}>
{eventT.tags&&eventT.tags.map((val,idx)=>
 <Chip  key={idx}  label={val}  className='chip_main'/>
)        
}  </Grid>  

<Grid item style={{padding:'1vh 0px',textAlign:'center'}} sm={12} xs={12}>
<Chip   label={loading?'Creating':'Create Event' } onClick={()=>Event()} className='chip_secondary'/>
            </Grid>

        </DialogContent>:

        <DialogContent>

<div className='bg_img_create'>
          {/* <label>
  <input type="file" class="hidden" name="file1">
  <img class="h-6 w-6" alt="Image here" src="/images/download1.svg">
</label> */}

<label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>handleImage1(e.target.files[0])}  name='file1'/>

               {data?.postImage? <img    htmlFor='file1'  src={data.postImage&&data.postImage.name?URL.createObjectURL(data.postImage):data.postImage} style={{width:'100%',height:'22vh',objectFit:'contain',borderTopLeftRadius:15,borderTopRightRadius:15,cursor:'pointer'}} />
:
          <i  htmlFor='file1'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'var(--blueColor)',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>

          {/* <input  type='file' onChange={(e)=>console.log(e.target.files[0])} /> */}
         {!data.postImage? <div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'var(--blueColor)'}}>Upload Post Image</h7>
          </div>:null}
            </div>



                                     <MyEditorS  quillChange={quillChange} editor={editor}/>

        
        <div style={{width:'100%',textAlign:'center',paddingTop:'1vh'}}>
        <Chip onClick={()=>Post()}   label={loadingPost?'Posting':'Create Post'}  className='chip_secondary'/>

        </div>
        </DialogContent>
        }
       
      </Dialog>


        <Grid  style={{height:'100%',alignContent:'center'}} container data-aos="zoom-in" >
        <Grid item  sm={12} xs={12}>
                <Typography className='head_people' data-aos="zoom-in"> Try searching for your co-founders, investors, partners.</Typography>
            </Grid>
            
            <Grid container  sm={3} xs={12}>

            <Grid item  sm={12} xs={12}>
                <Paper style={{background:'rgba(255,255,255,0.8)',boxShadow:'none',maxWidth:'fit-content',borderRadius:6}}>
                <Typography className='text_location'><span style={{paddingRight:10}}><i class="fas fa-map-marker-alt"></i></span>{location?.city}</Typography>
                </Paper>
            </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>

            <TextField
            style={{background:'rgba(255,255,255,0.8)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold'}}
        id="input-with-icon-textfield"
        // label="TextField"
        placeholder={`Search for ${active}`}
        value ={props.search}

        onChange={(e)=>props.Search(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{color:'var(--blueColor)'}} />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />

            </Grid>
            <Grid style={{textAlign:'-webkit-right'}} item sm={3} xs={12}>
            <Paper style={{background:'rgba(255,255,255,0.8)',boxShadow:'none',maxWidth:'fit-content',borderRadius:6,cursor:'pointer'}}>
                <Typography   onClick={()=>Open(true)} className='text_location'> Create a post / event</Typography>
                </Paper>
            </Grid>
           
        </Grid>
       
    </Grid>
</Container>
    </div>
  )
}
