import React from 'react'
import './p_people.css'
import { useParams } from 'react-router-dom';
import { Avatar, Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, Container, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MessageIcon from '@mui/icons-material/Message';
import { useEditProfileMutation, useFetchAllProfilesQuery, useFetchProfileQuery } from '../../slices/usersApiSlice';
import dayjs from 'dayjs';
import { useFetchUsersPostQuery } from '../../slices/postSlice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CheckCircle } from '@mui/icons-material';
import { toast } from 'react-toastify';
export default function Particular_people() {

   let {id}=useParams() 
   const {data:user,isLoading,isError,refetch:refetchUser}=useFetchProfileQuery(id);
   const {data:users,isLoading:loadingUsers,isError:errorUsers,refetch:refetchUsers}=useFetchAllProfilesQuery()
   const {userInfo}=useSelector((state)=>state.auth)

   const arr=[1,2,3,4,5,6,1,2,3,4,5,6]
   const {data:posts,isLoading:loadingP}=useFetchUsersPostQuery(id);
   const [editProfile,{}]=useEditProfileMutation()

   
  //  console.log(isError)
   
   useEffect(() => {
if(isError)   
     window.location.href='/people'
   }, [isLoading])
   const Connect=async(val)=>{
    if(userInfo){
      let connection={
        _id:val._id,
        connection:
       { 
        id:userInfo._id}
      }
    
    try {
      
    const res=await editProfile(connection)
    if(res){
      // refetch()
      refetchUser()
      refetchUsers()
    }
    
    } catch (error) {
       toast.error(error?.data?.message||error.error)
    
      
    }}
    else{
      toast.error('Please sign in to continue ..')
    }
      // refetch()
     }
   return (

    <div style={{width:'100%',background:'#eee',minHeight:'110vh'}}>
    <Container style={{paddingTop:110}}>
        {
    user&&!isLoading?<Grid container>
            <Grid container style={{marginBottom:'5vh'}} sm={8} xs={12}>
                <Paper style={{minHeight:'40vh',width:'100%',borderRadius:15}}>
                {user?.coverImage? <img  htmlFor='file1'  src={user.coverImage} style={{width:'100%',height:'30vh',objectFit:'cover',borderTopLeftRadius:15,borderTopRightRadius:15,cursor:'pointer'}} />

:<i   style={{width:'100%',fontSize:45,textAlign:'center',padding:'2vh 0px',color:'var(--blueColor)',cursor:'pointer',width:'100%',height:'30vh',objectFit:'contain',borderTopLeftRadius:15,borderTopRightRadius:15,cursor:'pointer',background:'#eee',alignItems:'center',display:'flex',justifyContent:'center'}} class="fas fa-camera-retro"></i>
}                    <Avatar  sx={{height:120,width:120,border:'3px solid white',marginTop:'-80px',marginLeft:'1vw'}} alt='avatar' src={user.profileImage?user.profileImage:require('../images/placeholder-avatar.jpg')}></Avatar>


<Grid container style={{padding:'0px 1.5rem'}}> 

<Grid  style={{padding:'1vh 0px 0px 0px'}}item sm={12} xs={12}>
    <Typography variant='h5' style={{fontWeight:'bold',color:'var(--blueColor)'}} >{user.fname?user.fname:''} {user.lname?user.lname:''}</Typography>
</Grid>
{user.designation?<Grid  style={{padding:'1vh 0px 0px 0px',maxWidth:'80%'}}item sm={12} xs={12}>
    <Typography variant='h7'  >{user.designation?(user.designation+' at '+ user.company):''}</Typography>
</Grid>:null}
{user.profileDescription?<Grid  style={{padding:'1vh 0px 0px 0px',maxWidth:'80%'}}item sm={12} xs={12}>
    <Typography color={'text.secondary'} variant='h8'  >{user.profileDescription}</Typography>
</Grid>:null}
{user.city?<Grid  style={{padding:'1vh 0px 0px 0px',maxWidth:'80%'}}item sm={12} xs={12}>
    <Typography  variant='h8' style={{fontWeight:'500',color:'var(--blueColor)'}} ><span style={{paddingRight:10}}><i class="fas fa-map-marker-alt"></i></span>{(user.city?`${user.city}, `:'') +(user.stat?`${user.stat}, `:'')+(user.country?`${user.country}`:'')} </Typography>
</Grid>:null}
<Grid  style={{padding:'1vh 0px 0px 0px',maxWidth:'80%'}}item sm={12} xs={12}>
    <Typography  variant='h9' style={{fontWeight:'500',color:'var(--blueColor)',fontStyle:'italic'}} >{user.connections.length<1?null:user.connections.length===1?`1 Connection ` : `${user.connections.length} Connections` } | {posts?(posts.length<1?null:posts.length===1?`1 Post ` : `${posts.length} Posts`):null} </Typography>
</Grid>


<Grid item style={{textAlign:'start',padding:'2vh 0px'}}  sm={12} xs={12}>
    
{userInfo&&user._id!==userInfo._id?


(
  user.connections&&user.connections[0]&&user.connections.find(val=>val.user._id===userInfo._id)?
<Chip
className='chip_main'
label="Connected"


onDelete={()=>console.log('')}
deleteIcon={<CheckCircle style={{color:'var(--blueColor)'}} />}
/>:
<Chip
className='chip_main'
label="Connect"

onClick={()=>Connect(user)}
onDelete={()=>Connect(user)}
deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>)
:
userInfo&&user._id===userInfo._id?null
:<Chip
className='chip_main'
label="Connect"
onClick={()=>Connect(user)}
onDelete={()=>Connect(user)}
deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>
}





<Chip
className='chip_main'
style={{padding:'0px 1vw'}}
  label="Posts"
onDelete={()=>console.log('')}
deleteIcon={<MessageIcon style={{color:'var(--blueColor)'}} />}
/>     
 </Grid>

</Grid>
                </Paper>
{                user.about?<Paper style={{marginTop:'5vh',width:'100%',borderRadius:15}}>
                    
                    <Grid container style={{padding:'0px 1.5rem'}}> 
                    
                    
                    <Grid  style={{padding:'1vh 0px 0px 0px',maxWidth:'80%'}}item sm={12} xs={12}>
                        <Typography variant='h7' style={{fontWeight:'bold',fontSize:'1.5rem'}}  >About</Typography>
                    </Grid>
                    <Grid style={{padding:'2vh 0px',maxHeight:'28vh',overflow:'scroll'}} item sm={12} xs={12}>
                    <Typography dangerouslySetInnerHTML={{__html: user.about}}  style={{fontWeight:'400',fontSize:'1.1rem',lineHeight:1.7}}>
                     
                     </Typography>
                    </Grid>
                    
                    </Grid>
                                    </Paper>:null}
                


                                    {!loadingP&&posts[0]?
                <Paper style={{marginTop:'5vh',width:'100%',borderRadius:15}}>
                    
{loadingP?<Box sx={{ display: 'flex' ,justifyContent:'center',marginTop:'20vh'}}>
      <CircularProgress  style={{height:50,width:50}}/>
    </Box>:
    <Grid container style={{padding:'0px 1.5rem'}}> 


<Grid  style={{padding:'2vh 0px 0px 0px',maxWidth:'80%'}}item sm={12} xs={12}>
    <Typography variant='h7' style={{fontWeight:'bold',fontSize:'1.5rem'}}  >Posts <span style={{fontWeight:'lighter'}} color='text.secondary' >({posts.length})</span></Typography>
</Grid>
<Grid style={{padding:'2vh 0px',maxHeight:'45vh',overflow:'scroll'}} item sm={12} xs={12}>
{posts.map((val,idx)=><div>
<div key={idx} className='content_people'>
{val.postImage?     <img src={val.postImage} style={{width:100,height:100,borderRadius:5,objectFit:'contain'}} />
:null}     

<div style={{padding:'0px 1vw',width:'100%'}}>
<Typography style={{display: 'flex',height:100,

    alignItems: 'center'}}  variant="h7" color="text.secondary">
      <span style={{maxWidth:'80%',height:70}} dangerouslySetInnerHTML={{__html:val.post}} className='text_readmore'></span>

      </Typography>
           <b style={{cursor:'pointer',marginTop:5}} onClick={()=>window.location.href=`/post/${val._id}`} >Read more</b>

</div>


     </div>
     <Grid container style={{padding:'1vh 0px'}}>
      <Grid  style={{padding:'2vh 0px 0px 0px',maxWidth:'80%'}}item sm={6} xs={6}>
    <Typography variant='h10' style={{fontWeight:'lighter',fontStyle:'italic'}}  >    {val.likes.length<1?null:val.likes.length===1?`1 like ` : `${val.likes.length} likes` }
</Typography>
</Grid>
<Grid  style={{padding:'2vh 0px 0px 0px',maxWidth:'80%',textAlign:'right'}}item sm={6} xs={6}>
    <Typography variant='h10' style={{fontWeight:'lighter',fontStyle:'italic'}}  >   {val.comments.length<1?null:val.comments.length===1?`1 Comment ` : `${val.comments.length} Comments` }
</Typography>
</Grid> 

<Grid  style={{background:'#eee',height:2,margin:'1vh 0px'}} item sm ={12} xs={12}>

</Grid>
</Grid>
</div>)}
</Grid>

</Grid>}
                </Paper>:null}

                {user.experiences&&user.experiences[0]?<Paper style={{marginTop:'5vh',width:'100%',borderRadius:15}}>
                    
                    <Grid container style={{padding:'0px 1.5rem'}}> 
                    
                    
                    <Grid  style={{padding:'1vh 0px 0px 0px',maxWidth:'80%'}}item sm={12} xs={12}>
                        <Typography variant='h7' style={{fontWeight:'bold',fontSize:'1.5rem'}}  >Experience</Typography>
                    </Grid>

                    <Grid style={{padding:'2vh 0px',maxHeight:'45vh',overflow:'scroll'}} container >
                   
                  {user.experiences.map((val,idx)=><Grid key={idx} container sm={12} xs={12}>
                <Grid item style={{padding:'5px 5px',display:'flex'}} sm={12} xs={12}>
                    <Typography style={{color:'var(--blueColor)',fontWeight:'500',width:'90%'}} variant='h6'>{val.designation}
</Typography>

                </Grid>
                <Grid item style={{padding:'5px 5px'}} sm={12} xs={12}>
                    <Typography style={{}} variant='h7'>
{val.company}  · {val.jobType}
</Typography>
                </Grid>
                <Grid item style={{padding:'5px 5px'}} sm={12} xs={12}>
                    <Typography  style={{}} variant='h8'>
{dayjs(val.startDate).format('YYYY/MM/DD')} - {dayjs(val.endDate).format('YYYY/MM/DD')} 
</Typography>
                </Grid>
                <Grid item style={{padding:'1vh 5px'}}  sm={12} xs={12}>
                    <Typography color={'text.secondary'} variant='h9'>
{val.jobLocation} · {val.workType}</Typography>
                </Grid>
                <Grid item  sm={12} xs={12}>
   {val.skills.map((val,idx)=>
     <Chip
     className='chip_main'
     style={{padding:'0px 1vw'}}
       label={val}
     />
   )            }   


                </Grid>
                <Grid item style={{padding:'1vh 5px'}}  sm={12} xs={12}>
                    <Typography color={'text.secondary'} style={{lineHeight:1.9}} variant='h9'>
                    {val.description}</Typography>                </Grid>
                <Grid  style={{background:'#eee',height:2,margin:'1vh 0px'}} item sm ={12} xs={12}>

</Grid>
                    </Grid>)}
                    
                    </Grid> 
                    </Grid>
                                    </Paper>:null}

                                    {user.skills&&user.skills[0]?<Paper style={{marginTop:'5vh',width:'100%',borderRadius:15}}>
                    
                    <Grid container style={{padding:'0px 1.5rem'}}> 
                    <Grid  style={{padding:'1vh 0px 0px 0px',maxWidth:'80%'}}item sm={12} xs={12}>
                        <Typography variant='h7' style={{fontWeight:'bold',fontSize:'1.5rem'}}  >Skills</Typography>
                    </Grid>
                    <Grid style={{padding:'1vh 0px'}} item  sm={12} xs={12}>
             {
              user.skills.map((val,idx)=>
              <Chip
            className='chip_secondary'
            style={{padding:'0px 1vw'}}
              label={val}
            /> )
             }

                </Grid>
               
                    
                    </Grid>
                                    </Paper>:null}

            
            </Grid>
            <Grid container style={{padding:'0px 20px'}} sm={4} xs={12}>
            <Paper style={{padding:'0px 10px',background:'rgba(5, 17, 33, 0.79)',boxShadow:'none',borderRadius:7,width:'100%',maxHeight:'100vh',overflow:'scroll'}}>
            
            <Grid item sm={12} xs={12}>
<Typography style={{fontSize:17,fontWeight:'600',padding:'2vh 5px',color:'white'}}>
    People matching your preferences
</Typography>
</Grid>
{

(loadingUsers||loadingP||isLoading)?

<Box sx={{ display: 'flex' ,justifyContent:'center',marginTop:'20vh',width:'100%'}}>
<CircularProgress  style={{height:40,width:40,color:'white'}}/>
</Box>:        

            
            
           
((userInfo&&!isLoading?(users?.filter(val=>((val._id!==userInfo._id)&&(val._id!==user._id)))):users?.filter(val=>val._id!==user._id)).map((val,idx)=>

  <Grid key={idx} 
  item sm={12} xs={12}>
  <Card  style={{margin:15,cursor:'pointer'}}  onClick={()=>window.location.href=`/people/${val._id}`} sx={{ minWidth: 250 }}>
  <CardContent>
   <div className='content_people'>
   {val.profileImage?<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} src={val.profileImage} ></Avatar>:<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} >{val.fname&&val.lname?(val.fname.charAt(0).toUpperCase()+val.lname.charAt(0).toUpperCase()):val.email.charAt(0).toUpperCase()}</Avatar>}
   
  
  <div style={{padding:'0px 5px'}}>
  <Typography variant="h6" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)',wordBreak:'break-word'}} component="div">
  {val.fname&&val.lname?(val.fname+' '+val.lname):val.email}
    </Typography>
    {val.designation?<Typography style={{margin:'2vh 0px'}} variant="h7" sx={{ mb: 1.5 }} >
      {val.designation}<b> | </b> {val.company}
      </Typography>:null}
    <div >
    {userInfo&&val._id!==userInfo._id?


(
  val.connections&&val.connections[0]&&val.connections.find(val=>val.user===userInfo._id)?
<Chip
className='chip_main'
label="Connected"


onDelete={()=>console.log('')}
deleteIcon={<CheckCircle style={{color:'var(--blueColor)'}} />}
/>:
<Chip
className='chip_main'
label="Connect"

onClick={()=>Connect(val)}
onDelete={()=>Connect(val)}
deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>)
:
userInfo&&val._id===userInfo._id?null
:<Chip
className='chip_main'
label="Connect"
onClick={()=>Connect(val)}
onDelete={()=>Connect(val)}
deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>
}
  </div>
  </div>
  
   </div>
    {/* <br /><br /> */}
  
  
  
  </CardContent>
  
  </Card>
  </Grid>
    ))
    
    }
    {/* {(userInfo&&(users.filter(val=>((val._id!==userInfo._id)[0]) ||(!userInfo&&users.filter(val=>val._id!==user._id)[0])?null:null
    } */}

    {
      (userInfo&&!isLoading&&(users?.filter(val=>(((val._id!==userInfo._id)&&(val._id!==user._id))))[0]))||(!userInfo&&users?.filter(val=>val._id!==user._id)[0])?null:
      <Typography style={{fontSize:'16px',padding:'2vh 0px',color:'white',textAlign:'center'}}>
    No Preferences found 
  </Typography>
    }
    </Paper>
            </Grid>
        </Grid>:<Box sx={{ display: 'flex' ,justifyContent:'center',marginTop:'20vh'}}>
      <CircularProgress  style={{height:50,width:50}}/>
    </Box>}
    </Container>
    </div>
  )
}
