import React, { useEffect, useState } from 'react'
import { Avatar, Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, Container, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, Paper, Radio, RadioGroup, Slide, TextField, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MessageIcon from '@mui/icons-material/Message';
import { useSelector } from 'react-redux';
import { useEditProfileMutation, useFetchProfileQuery, useUploadImageMutation } from '../../slices/usersApiSlice';
import { toast } from 'react-toastify';
import ReactPhoneInput from "react-phone-input-2";
 import 'react-phone-input-2/lib/style.css' 
 import { Close } from '@mui/icons-material';
import { MyEditorS } from '../people/editor';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Navigate } from 'react-router-dom';
import { useEditPostMutation, useFetchUsersPostQuery } from '../../slices/postSlice';

export default function Profile() {
    const {userInfo}=useSelector((state)=>state.auth)

const {data:user,isLoading,isError}=useFetchProfileQuery(userInfo?._id);
const {data:posts,isLoading:loadingP}=useFetchUsersPostQuery(userInfo?._id);

const [editProfile,{isLoading:loading}]=useEditProfileMutation()
const [uploadImage,{isLoading:loadingImage}]=useUploadImageMutation()
const [editPost,{isLoading:loadingPostE}]=useEditPostMutation()

const [editor,setEditor]=useState(true)
const [open, setOpen] = React.useState(false);
const [open2, setOpen2] = React.useState(false);

const [skill,setSkill]=useState('')
const [skillE,setSkillE]=useState('')
const [editE,setEditE]=useState('')
const [openEdit,setOpenEdit]=useState(false)
/// const [jobType,setJobType]=useState('')
const [experience,setExperience]=useState({})
// console.log(new Date(experience.startDate?.$d))
const [post1,setPost]=useState('')
const addSkills=()=>{
  if(skill){
    
setData({...data,skills:[...data.skills,skill]})

    // setDa([...skills,skill])
  }
 }


 useEffect(() => {
 if(!userInfo){
 window.location.href='/signin'
 }

 }, [])
//  console.log(posts)



 const addSkillsE=()=>{
  if(skillE){

   if(experience.skills){
    setExperience({
      ...experience,skills:[...experience.skills,skillE]
    })
  
   } 
   else{
    setExperience({
      ...experience,skills:[skillE]
    })
   }

   
    // setSkills([...skills,skill])
  }
 }






const [data,setData]=useState({})
useEffect(() => {
  if(user){

    setData({
      ...user
    })
  }
}, [user])
// console.log(data,'data')

const [value,setValue]=useState()
const quillChange=(val)=>{

  setData(data => ({ ...data, about:val}))
  
 }
 const quillChange1=(val)=>{

  setPost(post1 => ({ ...post1, post:val}))
  
 }
   const arr=[1,2,3,4,5,6,1,2,3,4,5,6]
// console.log(user,isLoading)
const [editSkill,setEditSkill]=useState(false)
const handleClose = () => {
  // setPost('')
  setOpen(false);
  setOpen2(false);

};
const Open=()=>{
  setEditor(data.about)
  setOpen(true)
}
// console.log(user)
const handleImage=async(val,type)=>{

  const formdata = new FormData()
  formdata.append('image',val)
  // console.log(val)

  if(type==='cover')
 { 
  
  setData({
    ...data, coverImage:val
  }) 
 }
 
 else{
  
  setData({
    ...data, profileImage:val
  }) 
 }

  try {
    
    const res=await uploadImage(formdata).unwrap()

    if(res){
      toast.success(res.message)
if(type==='cover'){
  setData({
    ...data,coverImage:res.image
  })  
}
else{
  setData({
    ...data,profileImage:res.image
  })  
}

       }

  } catch (error) {
    toast.error(error?.data?.message||error.error)

  }



}
// console.log(data)
const saveData=async(val)=>{

if((!val&&data.fname&&data.lname&&data.designation&&data.company&&data.profileDescription&&data.city&&data.stat&&data.country)||val)
{
  try {
    const res= await editProfile( val?val:data).unwrap()
    if(res){
      // console.log(res)

setOpen2(false)
setOpen(false)

      toast.success('Profile updated successfully')
          window.location.reload();

    }
    else{
      setOpen2(false)

      toast.error('Error occured')

    }
    
  } catch (error) {
    toast.error(error?.data?.message||error.error)
  }}
  else{
    toast.error('All (*) fields are mandatory')

  }
}
const addExperience=()=>{
// console.log(experience)
  

// let newData=({
//   ...data,experiences:[...data.experiences,experience]
// })

// console.log(newData)

  setData(data => ({ ...data,experiences:[...data.experiences,experience]}))

  let data_new=({ ...data,experiences:[...data.experiences,experience]})

  saveData(data_new)

}


const EditE=(val,idx)=>{
  setEditE(idx+1)
  setExperience({...val})
  setOpen2(true)
}

// console.log(editE)
const editExperience=()=>{

  
const data1=data.experiences.map(item=>({
  ...item
}))
data1[editE-1]={...experience}

let data_new=({ ...data,experiences:data1})


setData(data => ({ ...data,experiences:data1}))

saveData(data_new)







}
// console.log(data.coverImage?(URL.createObjectURL(data.coverImage)):'')
// URL.createObjectURL

const OpenEditPost =(val)=>{
  setEditor(val.post)
  setPost({...val})
  setOpenEdit(true)
}

const handleImage1=async(val)=>{
  // console.log(val)
        const formdata = new FormData()
        formdata.append('image',val)
        // console.log(val)
      
       
        setPost({
          ...post1, postImage:val
        }) 
      
      
        try {
          
          const res=await uploadImage(formdata).unwrap()
      
          if(res){
            toast.success(res.message)
            setPost({
              ...post1, postImage:res.image
            }) 
          
      
             }
      
        } catch (error) {
          toast.error(error?.data?.message||error.error)
      
        }
      
      
      
      }



      const editPost1=async()=>{


        try {
          const res=await editPost(post1).unwrap()

        if(res){
          toast.success(res.message)
          handleClose()
          window.location.reload()
        }
else{
  toast.error('Unable to edit post, please try again later')
  handleClose()
  
}
        } catch (error) {
          toast.error(error?.data?.message||error.error)

        }

      }

  return (

    <div style={{width:'100%',background:'#eee',minHeight:'110vh'}}>
    <Container style={{paddingTop:110}}>


    {/* <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {<DialogTitle style={{fontSize:22,fontWeight:'bold',color:'var(--blueColor)'}}>{`Brief About Yourself`}</DialogTitle>}
 <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        {


        <DialogContent>
                                     <MyEditorS  quillChange={quillChange} editor={editor}/>

        
        <div style={{width:'100%',textAlign:'center',paddingTop:'1vh'}}>
        <Chip   label={'Save'}  className='chip_secondary'/>

        </div>
        </DialogContent>
        }
       
      </Dialog> */}
      <Dialog
        open={open2}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {<DialogTitle style={{fontSize:22,fontWeight:'bold',color:'var(--blueColor)'}}  >{`Add Experience`}</DialogTitle>}
 <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        {

<DialogContent>

<Grid  style={{padding:'2vh 0px 0px 0px',maxWidth:'100%'}}item sm={12} xs={12}>
<TextField  style={{width:'98%'}} value={experience.designation} focused  onChange={(e)=>setExperience({...experience,designation:e.target.value})} multiline placeholder='Enter designation' label='Designation' />
</Grid>
<Grid  style={{padding:'2vh 0px 0px 0px',maxWidth:'100%'}}item sm={12} xs={12}>
<TextField  style={{width:'98%'}} focused multiline  onChange={(e)=>setExperience({...experience,company:e.target.value})} value={experience.company} placeholder='Enter company' label='Company' />
</Grid>
  <div style={{padding:'1vh 0px'}}>
  <h7 style={{width:'100%',fontSize:18,fontWeight:'600',color:'var(--blueColor)'}}>Job Type</h7>
<div style={{}}>
<FormControl>
<RadioGroup 
style={{flexDirection:'row'}}
// defaultValue="female"
name="controlled-radio-buttons-group"
onChange={(e)=>setExperience({...experience,jobType:e.target.value})} 
value={experience.jobType}
sx={{ my: 1 }}
>

<div style={{display:'flex',flexDirection:'row',alignItems:"center",paddingRight:'3vw'}}>
<Typography style={{fontSize:'16px',fontWeight:'600'}}>
Full-Time
</Typography>

<Radio value="Full-Time" checked={experience.jobType==='Full-Time'?true:false} label="Full-Time"   />
</div>
<div style={{display:'flex',flexDirection:'row',alignItems:"center"}}>
<Typography style={{fontSize:'16px',fontWeight:'600'}}>
Part-Time
</Typography>

<Radio value="Part-Time" checked={experience.jobType==='Part-Time'?true:false}  label="Part-Time"   />
</div>      </RadioGroup>
</FormControl>
</div>
  </div>
  <Grid  style={{padding:'2vh 0px 0px 0px',maxWidth:'100%'}}item sm={12} xs={12}>
<TextField  style={{width:'98%'}} multiline  focused onChange={(e)=>setExperience({...experience,jobLocation:e.target.value})} value={experience.jobLocation} placeholder='Enter job location' label='Job Location' />
</Grid>
  <Grid container style={{padding:'1vh 0px'}} sm={12} xs={12}>
            <Grid container style={{paddingRight:10}} sm={6} xs={12}>
            <LocalizationProvider  dateAdapter={AdapterDayjs}>
        <DatePicker 
          label="Start Date"
          focused onChange={(newValue)=>setExperience({...experience,startDate:new Date(newValue.$d)})} 
          
          value={experience.startDate?dayjs(experience.startDate):value}
        />
    </LocalizationProvider>  
              </Grid>
              <Grid container style={{paddingRight:10}} sm={6} xs={12}>
            <LocalizationProvider  dateAdapter={AdapterDayjs}>
        <DatePicker 
          label="End Date"
          focused onChange={(newValue)=>setExperience({...experience,endDate:new Date(newValue.$d)})} 
          value={experience.endDate?dayjs(experience.endDate):value}   />
    </LocalizationProvider>  
              </Grid>    
  
        </Grid>
        <div style={{padding:'1vh 0px'}}>
  <h8 style={{width:'100%',fontSize:18,fontWeight:'600',color:'var(--blueColor)'}}>Work Type</h8>
<div style={{}}>
<FormControl>
<RadioGroup 
style={{flexDirection:'row'}}
// defaultValue="female"
name="controlled-radio-buttons-group"
focused onChange={(e)=>setExperience({...experience,workType:e.target.value})} 
value={experience.workType}
sx={{ my: 1 }}
>

<div style={{display:'flex',flexDirection:'row',alignItems:"center",paddingRight:'3vw'}}>
<Typography style={{fontSize:'16px',fontWeight:'600'}}>
Work From Office
</Typography>

<Radio checked={experience.workType==='Work From Office'?true:false}  value="Work From Office" label="Work From Office"   />
</div>
<div style={{display:'flex',flexDirection:'row',alignItems:"center"}}>
<Typography style={{fontSize:'16px',fontWeight:'600'}}>
Remote
</Typography>

<Radio checked={experience.workType==='Remote'?true:false}  value="Remote" label="Remote"   />
</div>      </RadioGroup>
</FormControl>
</div>
  </div>

  <Grid container style={{padding:'1vh 0px'}} sm={12} xs={12}>
     

</Grid>




  



  <Grid container alignItems={'center'} style={{padding:'1vh 0px'}} sm={12} xs={12}>
  <Grid container style={{paddingRight:10}} sm={9} xs={9}>
  <TextField  focused onChange={(e)=>setSkillE(e.target.value)} style={{width:'100%'}} variant={'outlined'} label='Skill' placeholder='Enter skill' />

    </Grid>
  <Grid container  sm={3} xs={3}>
<Chip   label='Add Skill' onClick={()=>addSkillsE()}  className='chip_secondary'/>
</Grid>      

</Grid>

<Grid style={{flexWrap:'wrap'}} container  sm={12} xs={12}>
{experience.skills&&experience.skills.map((val,idx)=>
<Chip  key={idx}  label={val}  className='chip_main'/>
)        
}  </Grid>  

<Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
    <TextField focused onChange={(e)=>setExperience({...experience,description:e.target.value})} 
value={experience.description}  style={{width:'100%'}} multiline variant={'outlined'} label='Description' placeholder='Enter description' />
  </Grid>
<Grid item style={{padding:'1vh 0px',textAlign:'center'}} sm={12} xs={12}>
{editE?<Chip  onClick={()=>editExperience()} label={loading?'Saving':'Save Experience'}  className='chip_secondary'/>:<Chip  onClick={()=>addExperience()} label={loading?'Adding':'Add Experience'}  className='chip_secondary'/>
}  </Grid>

</DialogContent>
     
        }
       
      </Dialog>




        {isLoading? <Box sx={{ display: 'flex' ,justifyContent:'center',marginTop:'20vh'}}>
      <CircularProgress  style={{height:50,width:50}}/>
    </Box>:
        <Grid container>
            <Grid container style={{marginBottom:'5vh'}} sm={8} xs={12}>
                <Paper style={{minHeight:'40vh',width:'100%',borderRadius:15}}>
                <label>
            <input type='file' onChange={(e)=>handleImage(e.target.files[0],'cover')} style={{display:'none'}}   name='file0'/>
           
           {data?.coverImage? <img  htmlFor='file1'  src={data.coverImage&&data.coverImage.name?URL.createObjectURL(data.coverImage):data.coverImage} style={{width:'100%',height:'30vh',objectFit:'cover',borderTopLeftRadius:15,borderTopRightRadius:15,cursor:'pointer'}} />

            :<i  htmlFor='file1' style={{width:'100%',fontSize:45,textAlign:'center',padding:'2vh 0px',color:'var(--blueColor)',cursor:'pointer',width:'100%',height:'30vh',objectFit:'contain',borderTopLeftRadius:15,borderTopRightRadius:15,cursor:'pointer',background:'#eee',alignItems:'center',display:'flex',justifyContent:'center'}} class="fas fa-camera-retro"></i>
}
                    </label>
                    
                    <label>
            <input  onChange={(e)=>handleImage(e.target.files[0],'profile')} type='file' style={{display:'none'}}   name='file2'/>
            <Avatar htmlFor='file2' sx={{height:120,width:120,border:'3px solid white',marginTop:'-80px',marginLeft:'1vw',cursor:'pointer'}} alt='avatar' src={data?.profileImage?.name?  URL.createObjectURL(data.profileImage):!data.profileImage?require('../images/placeholder-avatar.jpg'):data.profileImage}></Avatar>

          {/* <i   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'var(--blueColor)',cursor:'pointer'}} class="fas fa-camera-retro"></i> */}
          </label>


<Grid container style={{padding:'0px 1.5rem'}}> 

<Grid  container item sm={12} xs={12}>
<Grid  style={{padding:'2vh 0px 0px 0px'}} item sm={6} xs={12}>
<TextField  style={{width:'95%'}}   placeholder='Enter first name' 
value={data.fname}
onChange={(e)=>setData({
  ...data,fname:e.target.value
})}  label='First Name*' />
</Grid>
<Grid  style={{padding:'2vh 0px 0px 0px'}} item sm={6} xs={12}>
<TextField value={data.lname}
onChange={(e)=>setData({
  ...data,lname:e.target.value
})}  style={{width:'95%'}} placeholder='Enter last name*' label='Last Name' />

</Grid>
</Grid>
<Grid  style={{padding:'2vh 0px 0px 0px',maxWidth:'100%'}}item sm={12} xs={12}>
<TextField  value={data.designation}
onChange={(e)=>setData({
  ...data,designation:e.target.value
})}  style={{width:'98%'}}  multiline placeholder='Enter designation' label='Designation*' />
</Grid>
<Grid  style={{padding:'2vh 0px 0px 0px',maxWidth:'100%'}}item sm={12} xs={12}>
<TextField  value={data.company}
onChange={(e)=>setData({
  ...data,company:e.target.value
})}  style={{width:'98%'}}  multiline placeholder='Enter company' label='Company*' />
</Grid>
<Grid  style={{padding:'2vh 0px 0px 0px',maxWidth:'100%'}}item sm={12} xs={12}>
<TextField   value={data.profileDescription}
onChange={(e)=>setData({
  ...data,profileDescription:e.target.value
})}  style={{width:'98%'}} multiline placeholder='Enter short description' label='Short Description*' />
</Grid>


<Grid  container style={{padding:'2vh 0px 0px 0px',paddingBottom:'4vh'}}item sm={12} xs={12}>
<Grid  style={{padding:'2vh 0px 0px 0px'}} item sm={6} xs={12}>
<ReactPhoneInput className="PN_Input2"

                // style={{color: 'white' }}
                inputProps={{
                  name: 'phonenumber',
                  required: true
                }}
               buttonStyle={{
                  //  backgroundColor:'var(--blueColor)',
                //    color:'black'
               }}
            containerStyle={{          
                      //  backgroundColor:'var(--blueColor)',
          }}
                dropdownStyle={{
                  // backgroundColor:'var(--blueColor)',

                }}
                country={'in'}
                value={data.phonenumber}  
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    return 'Invalid value: '+value+', '+country.name;
                  } else if (value.match(/1234/)) {
                    return false;
                  }
                  else if (value.match(/1234/)) {
                    return 'Phone number is invalid';
                  }
                   else {
                    return true;
                  }
                }}
                
                onChange={(code,phonenumber,num,val) =>
                  
                  // console.log()
                  setData({
                 
                    // ...data,phonenumber:code.replace(phonenumber.dialCode,''),contryCode:'+'+phonenumber.dialCode

                  ...data,phonenumber:code,contryCode:'+'+phonenumber.dialCode
                })
              }  

                // onChange={phonenumber =>setData({...data,phone: phonenumber })}  
              />
{/* <TextField  style={{width:'95%'}} placeholder='Enter phonenumber' label='Phonenumber' /> */}
</Grid>
<Grid  style={{padding:'2vh 0px 0px 0px'}} item sm={6} xs={12}>
<TextField   value={data.addressLine1}
onChange={(e)=>setData({
  ...data,addressLine1:e.target.value
})} style={{width:'95%'}} placeholder='Enter addressline1' label='Address Line1' />

</Grid>
<Grid  style={{padding:'2vh 0px 0px 0px'}} item sm={6} xs={12}>
<TextField  value={data.addressLine2}
onChange={(e)=>setData({
  ...data,addressLine2:e.target.value
})} style={{width:'95%'}} placeholder='Enter addressline2' label='Address Line2' />

</Grid>
<Grid  style={{padding:'2vh 0px 0px 0px'}} item sm={6} xs={12}>
<TextField   value={data.city}
onChange={(e)=>setData({
  ...data,city:e.target.value
})} style={{width:'95%'}} placeholder='Enter city' label='City*' />

</Grid>
<Grid  style={{padding:'2vh 0px 0px 0px'}} item sm={6} xs={12}>
<TextField  value={data.stat}
onChange={(e)=>setData({
  ...data,stat:e.target.value
})} style={{width:'95%'}} placeholder='Enter state' label='State*' />

</Grid>
<Grid  style={{padding:'2vh 0px 0px 0px'}} item sm={6} xs={12}>
<TextField   value={data.country}
onChange={(e)=>setData({
  ...data,country:e.target.value
})} style={{width:'95%'}} placeholder='Enter country' label='Country*' />

</Grid>
<Grid  style={{padding:'2vh 0px 0px 0px'}} item sm={6} xs={12}>
<TextField  value={data.pincode}
onChange={(e)=>setData({
  ...data,pincode:e.target.value
})} style={{width:'95%'}} placeholder='Enter pincode' label='Pincode' />

</Grid>



<Grid item style={{textAlign:'start',padding:'2vh 0px'}}  sm={12} xs={12}>
    
{!open?     <Chip
onClick={()=>saveData()}

className='chip_secondary'
style={{padding:'0px 1vw'}}
label={loading?'Saving':'Save'} 
//  deleteIcon={<AddCircleIcon style={{color:'white'}} />}
/> :null }
</Grid>
</Grid>


</Grid>
                </Paper>
                <Paper style={{marginTop:'5vh',width:'100%',borderRadius:15}}>
                    
                    <Grid container style={{padding:'0px 1.5rem'}}> 
                    
                    
                    <Grid  style={{padding:'2vh 0px 0px 0px',display:'flex'}}item sm={12} xs={12}>
                        <Typography variant='h7' style={{fontWeight:'bold',fontSize:'1.5rem',width:'50%'}}  >About</Typography>
<div style={{width:'50%',textAlign:'end'}}>
                        {open?<Chip onClick={()=>saveData()}  label={loading?'Saving':'Save'}   className='chip_secondary'/>:<i class="far fa-edit" style={{cursor:'pointer'}} onClick={()=>Open()}></i>}
                        </div>

                    </Grid>
                    <Grid style={{padding:'2vh 0px',minHeight:'28vh',overflow:'scroll'}} item sm={12} xs={12}>
                 {!open? <> {data.about? <Typography dangerouslySetInnerHTML={{__html: data.about}} style={{fontWeight:'400',fontSize:'1.1rem',lineHeight:1.7}}>
                     
                    </Typography>:<Typography variant='h9'  style={{fontWeight:'lighter',fontSize:'1.2rem',lineHeight:1.7,textAlign:'center'}}>Edit and add about yourself</Typography>}
                   </>   :     
                   
                                      <MyEditorS  quillChange={quillChange} editor={editor}/>

        }

                    </Grid>
                    
                    </Grid>
                                    </Paper>
                {!loadingP&&posts&&posts[0]?
                <Paper style={{marginTop:'5vh',width:'100%',borderRadius:15}}>
                    
{loadingP?<Box sx={{ display: 'flex' ,justifyContent:'center',marginTop:'20vh'}}>
      <CircularProgress  style={{height:50,width:50}}/>
    </Box>:
    <Grid container style={{padding:'0px 1.5rem'}}> 


<Grid  style={{padding:'2vh 0px 0px 0px',maxWidth:'80%'}}item sm={12} xs={12}>
    <Typography variant='h7' style={{fontWeight:'bold',fontSize:'1.5rem'}}  >Posts <span style={{fontWeight:'lighter'}} color='text.secondary' >({posts.length})</span></Typography>
</Grid>
<Grid style={{padding:'2vh 0px',maxHeight:openEdit?'100vh':'45vh',overflow:'scroll'}} item sm={12} xs={12}>
{openEdit?
<>
<div style={{width:'100%',textAlign:'right',paddingTop:'1vh'}}>
        <Chip onClick={()=>editPost1()}   label={loadingPostE?'Saving':'Save Post'}  className='chip_secondary'/>

        </div>

<div className='bg_img_create'>
      

<label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>handleImage1(e.target.files[0])}  name='file1'/>

               {post1?.postImage? <img    htmlFor='file1'  src={post1.postImage&&post1.postImage.name?URL.createObjectURL(post1.postImage):post1.postImage} style={{width:'100%',height:'22vh',objectFit:'contain',borderTopLeftRadius:15,borderTopRightRadius:15,cursor:'pointer'}} />
:
          <i  htmlFor='file1'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'var(--blueColor)',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>

         {!post1.postImage? <div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'var(--blueColor)'}}>Upload Post Image</h7>
          </div>:null}
            </div>



            <MyEditorS  quillChange={quillChange1} editor={editor}/>

        
        
</>


:(posts.map((val,idx)=><div>
  <i class="far fa-edit" style={{width:'100%',alignSelf:'flex-end',textAlign:'end',cursor:'pointer'}} onClick={()=>OpenEditPost(val)}></i>

  <div key={idx} className='content_people'>
{val.postImage?     <img src={val.postImage} style={{width:100,height:100,borderRadius:5,objectFit:'contain'}} />
:null}     

<div style={{padding:'0px 1vw',width:'100%'}}>
<Typography style={{display: 'flex',height:100,

    alignItems: 'center'}}  variant="h7" color="text.secondary">
      <span style={{maxWidth:'80%',height:70}} dangerouslySetInnerHTML={{__html:val.post}} className='text_readmore'></span>

      </Typography>
           <b style={{cursor:'pointer',marginTop:5}} onClick={()=>window.location.href=`/post/${val._id}`} >Read more</b>

</div>


     </div>
     <Grid container style={{padding:'1vh 0px'}}>
      <Grid  style={{padding:'2vh 0px 0px 0px',maxWidth:'80%'}}item sm={6} xs={6}>
    <Typography variant='h10' style={{fontWeight:'lighter',fontStyle:'italic'}}  >    {val.likes.length<1?null:val.likes.length===1?`1 like ` : `${val.likes.length} likes` }
</Typography>
</Grid>
<Grid  style={{padding:'2vh 0px 0px 0px',maxWidth:'80%',textAlign:'right'}}item sm={6} xs={6}>
    <Typography variant='h10' style={{fontWeight:'lighter',fontStyle:'italic'}}  >{val.comments.length<1?null:val.comments.length===1?`1 Comment ` : `${val.comments.length} Comments` }</Typography>
</Grid> 

<Grid  style={{background:'#eee',height:2,margin:'1vh 0px'}} item sm ={12} xs={12}>

</Grid>
</Grid>
</div>))}
</Grid>

</Grid>}
                </Paper>:null}
                <Paper style={{marginTop:'5vh',width:'100%',borderRadius:15}}>
                    
                    <Grid container style={{padding:'0px 1.5rem'}}> 
                    
                    
                    <Grid  style={{padding:'2vh 0px 0px 0px',display:'flex'}}item sm={12} xs={12}>
                        <Typography variant='h7' style={{fontWeight:'bold',fontSize:'1.5rem',width:'50%'}}  >Experience</Typography>
                        <div style={{width:'50%',textAlign:'end'}}>
{open2?  <Chip
onClick={()=>saveData()}

className='chip_secondary'
style={{padding:'0px 1vw'}}
label={loading?'Saving':'Save'} 
//  deleteIcon={<AddCircleIcon style={{color:'white'}} />}
/> :                        <i class="fas fa-plus-circle" style={{cursor:'pointer'}} onClick={()=>setOpen2(true)}></i>
}                       
                        </div>
                    </Grid>

                    <Grid style={{padding:'2vh 0px',maxHeight:'45vh',overflow:'scroll'}} container >
                    {data&&data.experiences&&data.experiences[0]?data.experiences.map((val,idx)=><Grid key={idx} container sm={12} xs={12}>
                <Grid item style={{padding:'5px 5px',display:'flex'}} sm={12} xs={12}>
                    <Typography style={{color:'var(--blueColor)',fontWeight:'500',width:'90%'}} variant='h6'>{val.designation}
</Typography>
<i class="far fa-edit" style={{width:'10%',alignSelf:'center',textAlign:'end',cursor:'pointer'}} onClick={()=>EditE(val,idx)}></i>

                </Grid>
                <Grid item style={{padding:'5px 5px'}} sm={12} xs={12}>
                    <Typography style={{}} variant='h7'>
{val.company}  · {val.jobType}
</Typography>
                </Grid>
                <Grid item style={{padding:'5px 5px'}} sm={12} xs={12}>
                    <Typography  style={{}} variant='h8'>
{dayjs(val.startDate).format('YYYY/MM/DD')} - {dayjs(val.endDate).format('YYYY/MM/DD')} 
</Typography>
                </Grid>
                <Grid item style={{padding:'1vh 5px'}}  sm={12} xs={12}>
                    <Typography color={'text.secondary'} variant='h9'>
{val.jobLocation} · {val.workType}</Typography>
                </Grid>
                <Grid item  sm={12} xs={12}>
   {val.skills.map((val,idx)=>
     <Chip
     className='chip_main'
     style={{padding:'0px 1vw'}}
       label={val}
     />
   )            }   


                </Grid>
                <Grid item style={{padding:'1vh 5px'}}  sm={12} xs={12}>
                    <Typography color={'text.secondary'} style={{lineHeight:1.9}} variant='h9'>
                    {val.description}</Typography>                </Grid>
                <Grid  style={{background:'#eee',height:2,margin:'1vh 0px'}} item sm ={12} xs={12}>

</Grid>
                    </Grid>):
                    <Typography variant='h9'  style={{fontWeight:'lighter',fontSize:'1.2rem',lineHeight:1.7,textAlign:'center'}}>Edit and add your experiences</Typography>
                    }
                    
                    </Grid> 
                    </Grid>
                                    </Paper>

                                    <Paper style={{marginTop:'5vh',width:'100%',borderRadius:15}}>
                    
                    <Grid container style={{padding:'0px 1.5rem'}}> 
                    <Grid item style={{padding:'5px 5px',display:'flex'}} sm={12} xs={12}>
                    <Typography variant='h7' style={{fontWeight:'bold',fontSize:'1.5rem',width:'90%'}}  >Skills
</Typography>
{editSkill? <Chip
onClick={()=>saveData()}

className='chip_secondary'
style={{padding:'0px 1vw'}}
label={loading?'Saving':'Save'} 
//  deleteIcon={<AddCircleIcon style={{color:'white'}} />}
/>:<i class={"far fa-edit"}


style={{width:'10%',alignSelf:'center',textAlign:'end',cursor:'pointer'}} onClick={()=>setEditSkill(!editSkill)}></i>}







                </Grid>
                    <Grid style={{padding:'1vh 0px'}} item  sm={12} xs={12}>
   
{data.skills&&data.skills[0]?data.skills.map((val,idx)=>
  <Chip
className='chip_secondary'
style={{padding:'0px 1vw'}}
  label={val}
/> ):
data.skills&&!data.skills[0]&&!editSkill?
<Typography variant='h9'  style={{fontWeight:'lighter',fontSize:'1.2rem',lineHeight:1.7,textAlign:'center'}}>Edit and add your skills</Typography>:null}   
 
{editSkill?<Grid container alignItems={'center'} style={{padding:'1vh 0px'}} sm={12} xs={12}>
  <Grid container style={{paddingRight:10}} sm={9} xs={9}>
  <TextField  onChange={(e)=>setSkill(e.target.value)} style={{width:'100%'}} variant={'outlined'} label='Skill' placeholder='Enter skill' />

    </Grid>
  <Grid container  sm={3} xs={3}>
<Chip   label='Add Skill' onClick={()=>addSkills()}  className='chip_secondary'/>
</Grid>      

</Grid>:null}

                </Grid>
               
                    
                    </Grid>
                                    </Paper>

            
            </Grid>
            <Grid container style={{padding:'0px 20px'}} sm={4} xs={12}>
            <Paper style={{padding:'0px 10px',background:'rgba(5, 17, 33, 0.79)',boxShadow:'none',borderRadius:7,width:'100%',maxHeight:'100vh',overflow:'scroll'}}>
            
            <Grid item sm={12} xs={12}>
<Typography style={{fontSize:17,fontWeight:'600',padding:'2vh 5px',color:'white'}}>
   My Connections
</Typography>
</Grid>{
( user?.connections&& user.connections[0]?
  ( user.connections.map((val,idx)=> <Grid key={idx} 
  item sm={12} xs={12}>
  <Card  style={{margin:15}} sx={{ minWidth: 250 }}>
  <CardContent>
   <div className='content_people'>
   {val.user.profileImage?<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} src={val.user.profileImage} ></Avatar>:<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} >{val.user.fname&&val.user.lname?(val.user.fname.charAt(0).toUpperCase()+val.user.lname.charAt(0).toUpperCase()):val.user.email.charAt(0).toUpperCase()}</Avatar>}
   
  
  <div style={{padding:'0px 1vw'}}>
  <Typography variant="h6" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)',wordBreak:'break-word'}} component="div">
  {val.user.fname&&val.user.lname?(val.user.fname+' '+val.user.lname):val.user.email}
    </Typography>
    {val.user.designation?<Typography style={{margin:'2vh 0px'}} variant="h7" sx={{ mb: 1.5 }} >
       {val.user.designation}<b> | </b> {val.user.company}
       </Typography>:null}
    <div >
  <Chip
  className='chip_main'
  label="Message"
  // onDelete={()=>console.log('')}
  // deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
  />
  </div>
  </div>
  
   </div>
    
  
  </CardContent>
  
  </Card>
  </Grid>)):<Card  style={{margin:15}} sx={{ minWidth: 250 }}>
<CardContent>
 <div className='content_people'>
 

<div style={{padding:'0px 1vw'}}>
<Typography variant="h6" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)'}} component="div">
  No connections found
  </Typography>

</div>

 </div>
  

</CardContent>

</Card>)}
    </Paper>
            </Grid>
        </Grid>}
    </Container>
    </div>
  )
}
