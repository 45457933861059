import { POSTS_URL } from "../constants";
import { apiSlice } from "./apiSlice";
export const postApiSlice=apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        createPost:builder.mutation({
            query:(data)=>({
                url:`${POSTS_URL}/post`,
                method:'POST',
                body:data

            })

        }),
        fetchParticularPost:builder.query({
            query:(id)=>({
                url:`${POSTS_URL}/all/${id}`


            }),
            keepUnusedDataFor:5,

        }),
        fetchAllPosts:builder.query({
            query:()=>({
                url:`${POSTS_URL}/post`


            }),
            keepUnusedDataFor:5,

        }),
        fetchUsersPost:builder.query({
            query:(id)=>(
                id?
                {
                url:`${POSTS_URL}/post/${id}`,
               

            }:null),
            keepUnusedDataFor:5


        }),
        editPost:builder.mutation({
            query:(data)=>({
                url:`${POSTS_URL}/post/${data._id}`,
                method:'PUT',
                body:data
               

            })


        }),
    
})})

export const {useCreatePostMutation,useFetchParticularPostQuery,useFetchAllPostsQuery,useFetchUsersPostQuery,useEditPostMutation}=postApiSlice