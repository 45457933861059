// import { Box, Button, Card, CardActions, CardContent, Container, DialogContent, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material'

import {CircularProgress, Avatar, Box, Button, Card, CardActions,CardContent ,Checkbox, Chip, Container, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, Paper, Radio, RadioGroup, Slide, TextField, Typography } from '@mui/material'
import MapsUgcRoundedIcon from '@mui/icons-material/MapsUgcRounded';import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

import './home_below_top.css'
import React, { useEffect, useState } from 'react'
import Aos from 'aos';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import { CheckCircle, Close, Search } from '@mui/icons-material';
import 'aos/dist/aos.css'; 
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MessageIcon from '@mui/icons-material/Message';

import SearchIcon from '@mui/icons-material/Search';
import { MyEditorS } from '../people/editor';
import { useCreatePostMutation, useEditPostMutation, useFetchAllPostsQuery } from '../../slices/postSlice';
import { useCreateEventMutation, useFetchLocationQuery } from '../../slices/eventSlice';
import { useEditProfileMutation, useFetchAllProfilesQuery, useUploadImageMutation } from '../../slices/usersApiSlice';
export default function Home_below_top() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const [value, setValue] = React.useState(dayjs(new Date()));
  const {userInfo}=useSelector((state)=>state.auth)
const [createPost,{isLoading:loadingPost}]=useCreatePostMutation()
const [end_timings,setEndTimings]=useState(true)
const [open, setOpen] = React.useState(false);
const [tag,setTag]=useState('')
const [post,setPost]=useState('')
const [tags,setTags]=useState([])


const [comment_text,setCommentText]=useState('')
   const [data,setData]=useState({creator:userInfo?._id}) 
    
   const[createEvent,{isLoading:loading}]=useCreateEventMutation()

   const[editPost,{isLoading:loadingPostE}]=useEditPostMutation()

   const{ data:location,isLoading:loadingLocation}=useFetchLocationQuery()

   const {data:posts,isLoading:loadingPosts,isError:errorPost,refetch}=useFetchAllPostsQuery()
   const {data:users,isLoading:loadingUsers,isError:errorUsers,refetch:refetchUsers}=useFetchAllProfilesQuery()
   const [editProfile,{isLoading:loadingUser}]=useEditProfileMutation()

const [uploadImage,{isLoading:loadingImage}]=useUploadImageMutation()
const [eventT,setEvent]=useState({creator:userInfo?._id})
const [search,setSearch]=useState('')
const [searchResults,setSearchResults]=useState('')

const [comment,setComment]=useState('')
const addTags=()=>{
  if(tag){

    setEvent({
      ...eventT,tags:[...tags,'#'+tag]
    })
    setTags([...tags,'#'+tag])
  }
 }
 const handleClose = () => {
  setPost('')
  setOpen(false);
};
const [editor,setEditor]=useState(true)

const quillChange=(val)=>{

  // console.log(val)
  setData(data => ({ ...data, post:val}))
  
 }

 const handleImage=async(val)=>{
// console.log(val)
  const formdata = new FormData()
  formdata.append('image',val)
  // console.log(val)

 
  setEvent({
    ...eventT, eventImage:val
  }) 


  try {
    
    const res=await uploadImage(formdata).unwrap()

    if(res){
      toast.success(res.message)
      setEvent({
        ...eventT,eventImage:res.image
      })  

       }

  } catch (error) {
    toast.error(error?.data?.message||error.error)

  }



}
  const handleImage1=async(val)=>{
// console.log(val)
  const formdata = new FormData()
  formdata.append('image',val)
  // console.log(val)

 
  setData({
    ...data, postImage:val
  }) 


  try {
    
    const res=await uploadImage(formdata).unwrap()

    if(res){
      toast.success(res.message)
      setData({
        ...data,postImage:res.image
      })  

       }

  } catch (error) {
    toast.error(error?.data?.message||error.error)

  }



}
   const Open=()=>{
      if(userInfo){
        setOpen(true)
      }
      else{
        // Navigate('/signin')
    window.location.href='/signin'  }
    }


    
// console.log(eventT)
const Event=async()=>{
if(eventT.eventImage&&eventT.eventType&&eventT.name&&eventT.startDate&&eventT.startTime&&eventT.description){
 try {
    const res= await createEvent(eventT).unwrap()
    if(res){
      // console.log(res)

      toast.success(res.message)
      handleClose()
          window.location.reload();

    }
    else{

      toast.error('Error occured while creating event')

    }
    
  } catch (error) {
    toast.error(error?.data?.message||error.error)
  }
}

else{
  toast.error('All (*) fields are mandatory')
}
 

}
const Post=async()=>{
if(data.post){
  try {
    const res= await createPost(data).unwrap()
    if(res){
      // console.log(res)

      toast.success(res.message)
      handleClose()
          window.location.reload();

    }
    else{

      toast.error('Error occured while creating event')

    }
    
  } catch (error) {
    toast.error(error?.data?.message||error.error)
  }
}
else{
  toast.error('Post content required')
}
 
}
 const arr=[1,2,3,4,5,6,1,2,3,4,5,6]


 const Search=(val)=>{

  setSearch(val)
  // console.log(val)
if(((val.length>3)&&!loading&&posts)){

let results=posts?.filter((val1)=>(((val1.post.toLowerCase().includes(val.toLowerCase()))||(val1.creator?.lname?.toLowerCase().includes(val.toLowerCase()))||(val1.creator?.fname?.toLowerCase().includes(val.toLowerCase()))
)))
// console.log(results,'results')

setSearchResults(results)
}

 }


 const likePost=async(val)=>{
if(userInfo){
  let like={
    _id:val._id,
    like:
   { 
    id:userInfo._id}
  }

try {
  
const res=await editPost(like)
if(res){
  refetch()
}

} catch (error) {
   toast.error(error?.data?.message||error.error)

  
}}
else{
  toast.error('Please sign in to continue ..')
}
  // refetch()
 }

const Comment=(val)=>{
if(comment&&(val._id===comment._id)){
  setCommentText('')
  setComment('')

}
else{
  setComment(val)
  setCommentText('')


}

}

const postComment=async()=>{


  if(comment_text){
if(userInfo){
  let comment1={
    _id:comment._id,
    comment:
   { 
    id:userInfo._id,
    comment:comment_text
  }
  }

try {
  
const res=await editPost(comment1)
if(res){

  setCommentText('')
  refetch()
}

} catch (error) {
   toast.error(error?.data?.message||error.error)

  
}}
else{
  toast.error('Please sign in to continue ..')
}

  }
}
const Connect=async(val)=>{
  if(userInfo){
    let connection={
      _id:val._id,
      connection:
     { 
      id:userInfo._id}
    }
  
  try {
    
  const res=await editProfile(connection)
  if(res){
    refetch()
    refetchUsers()
  }
  
  } catch (error) {
     toast.error(error?.data?.message||error.error)
  
    
  }}
  else{
    toast.error('Please sign in to continue ..')
  }
    // refetch()
   }





// console.log(comment)

    return (
    <div style={{background:'var(--blueColor)'}}>

<Container style={{padding:'5vh 0px',paddingBottom:'5vh'}}>
   
   
<Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {!post?<DialogTitle style={{fontSize:22,fontWeight:'bold',color:'var(--blueColor)'}}>{"Create Event / Post"}</DialogTitle>
        :<DialogTitle style={{fontSize:22,fontWeight:'bold',color:'var(--blueColor)'}}>{`Create ${post}`}</DialogTitle>}
 <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        {!post?<DialogContent>
          <div    onClick={()=>setPost('Event')} className='click_event'>
Create event
          </div>
          <div  onClick={()=>setPost('Post')} className='click_post'>
          Create post

            </div>
        </DialogContent>
:post==='Event'?
 <DialogContent>
          <div className='bg_img_create'>
          {/* <label>
  <input type="file" class="hidden" name="file1">
  <img class="h-6 w-6" alt="Image here" src="/images/download1.svg">
</label> */}

<label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>handleImage(e.target.files[0])}  name='file1'/>

               {eventT?.eventImage? <img    htmlFor='file1'  src={eventT.eventImage&&eventT.eventImage.name?URL.createObjectURL(eventT.eventImage):eventT.eventImage} style={{width:'100%',height:'22vh',objectFit:'contain',cursor:'pointer'}} />
:
          <i  htmlFor='file1'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'var(--blueColor)',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>

          {/* <input  type='file' onChange={(e)=>console.log(e.target.files[0])} /> */}
         {!eventT.eventImage? <div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'var(--blueColor)'}}>Upload Event Image*</h7>
          </div>:null}
            </div>

            <div style={{padding:'1vh 0px'}}>
            <h7 style={{width:'100%',fontSize:18,fontWeight:'600',color:'var(--blueColor)'}}>Event Type*</h7>
<div style={{}}>
<FormControl>
      <RadioGroup 
      style={{flexDirection:'row'}}
        // defaultValue="female"
        name="controlled-radio-buttons-group"
        value={eventT.eventType}
        onChange={(e)=>setEvent({...eventT,eventType:e.target.value})}
        sx={{ my: 1 }}
      >

        <div style={{display:'flex',flexDirection:'row',alignItems:"center",paddingRight:'3vw'}}>
<Typography style={{fontSize:'16px',fontWeight:'600'}}>
  Online
</Typography>

        <Radio value="Online" label="Online"   />
        </div>
        <div style={{display:'flex',flexDirection:'row',alignItems:"center"}}>
<Typography style={{fontSize:'16px',fontWeight:'600'}}>
  In Person
</Typography>

        <Radio value="In Person" label="In Person"   />
        </div>      </RadioGroup>
    </FormControl>
</div>
            </div>
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              <TextField  style={{width:'100%'}}  variant={'outlined'} value={eventT.name}
        onChange={(e)=>setEvent({...eventT,name:e.target.value})}
         label='Event Name*' placeholder='Enter event name' />
            </Grid>
            <Grid container style={{padding:'1vh 0px'}} sm={12} xs={12}>
            <Grid container style={{paddingRight:10}} sm={6} xs={12}>
            <LocalizationProvider  dateAdapter={AdapterDayjs}>
        <DatePicker 
          label="Start Date*"
          value={eventT.startDate?eventT.startDate:value}
          onChange={(val)=>setEvent({...eventT,startDate:new Date(val.$d)})}
        />
    </LocalizationProvider>  
              </Grid>
            <Grid container  sm={6} xs={12}>
            <LocalizationProvider  dateAdapter={AdapterDayjs}>

            <TimePicker
  label="Start Time*"
  
  value={eventT.startTime?eventT.startTime:value}
  onChange={(val)=>setEvent({...eventT,startTime:new Date(val.$d)})}

/>          
</LocalizationProvider>
  </Grid>      
  
        </Grid>

        <FormGroup style={{padding:'1vh 0px'}}>
  <FormControlLabel onChange={(e)=>setEndTimings(e.target.checked)} control={<Checkbox checked={end_timings} />} label="Add end date & time" />
  </FormGroup>
 {end_timings? 
 <Grid container style={{padding:'1vh 0px'}} sm={12} xs={12}>
            <Grid container style={{paddingRight:10}} sm={6} xs={12}>
            <LocalizationProvider  dateAdapter={AdapterDayjs}>
        <DatePicker 
          label="End Date"
          value={eventT.endDate?eventT.endDate:value}
          onChange={(val)=>setEvent({...eventT,endDate:new Date(val.$d)})}
        
        />
    </LocalizationProvider>  
              </Grid>
            <Grid container  sm={6} xs={12}>
            <LocalizationProvider  dateAdapter={AdapterDayjs}>

            <TimePicker
  label="End Time"
  value={eventT.endTime?eventT.endTime:value}
  onChange={(val)=>setEvent({...eventT,endTime:new Date(val.$d)})}

/>          
</LocalizationProvider>
  </Grid>      
  
        </Grid>:null}
        {eventT.eventType==='In Person'?<>   <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              <TextField  style={{width:'100%'}} value={eventT.address}
        onChange={(e)=>setEvent({...eventT,address:e.target.value})}  variant={'outlined'} label='Address' placeholder='Enter event address' />
            </Grid>
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              <TextField  style={{width:'100%'}} variant={'outlined'} label='Venue' value={eventT.venue}
        onChange={(e)=>setEvent({...eventT,venue:e.target.value})}  placeholder='Enter event venue' />
            </Grid></>:null}
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              <TextField  style={{width:'100%'}} value={eventT.description}
        onChange={(e)=>setEvent({...eventT,description:e.target.value})}  variant={'outlined'} label='Description*' placeholder='Enter event description' />
            </Grid>
        <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              <TextField  style={{width:'100%'}}value={eventT.externalLink}
        onChange={(e)=>setEvent({...eventT,externalLink:e.target.value})}  variant={'outlined'} label='External Event Link' placeholder='Enter external event link' />
            </Grid>
         

            <Grid container alignItems={'center'} style={{padding:'1vh 0px'}} sm={12} xs={12}>
            <Grid container style={{paddingRight:10}} sm={9} xs={9}>
            <TextField  onChange={(e)=>setTag(e.target.value)} style={{width:'100%'}} variant={'outlined'} label='Event Tags' placeholder='Enter event tags' />

              </Grid>
            <Grid container  sm={3} xs={3}>
         <Chip   label='Add Tags' onClick={()=>addTags()}  className='chip_secondary'/>
  </Grid>      
  
        </Grid>

        <Grid style={{flexWrap:'wrap'}} container  sm={12} xs={12}>
{eventT.tags&&eventT.tags.map((val,idx)=>
 <Chip  key={idx}  label={val}  className='chip_main'/>
)        
}  </Grid>  

<Grid item style={{padding:'1vh 0px',textAlign:'center'}} sm={12} xs={12}>
<Chip   label={loading?'Creating':'Create Event' } onClick={()=>Event()} className='chip_secondary'/>
            </Grid>

        </DialogContent>:

        <DialogContent>

<div className='bg_img_create'>
          {/* <label>
  <input type="file" class="hidden" name="file1">
  <img class="h-6 w-6" alt="Image here" src="/images/download1.svg">
</label> */}

<label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>handleImage1(e.target.files[0])}  name='file1'/>

               {data?.postImage? <img    htmlFor='file1'  src={data.postImage&&data.postImage.name?URL.createObjectURL(data.postImage):data.postImage} style={{width:'100%',height:'22vh',objectFit:'contain',borderTopLeftRadius:15,borderTopRightRadius:15,cursor:'pointer'}} />
:
          <i  htmlFor='file1'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'var(--blueColor)',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>

          {/* <input  type='file' onChange={(e)=>console.log(e.target.files[0])} /> */}
         {!data.postImage? <div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'var(--blueColor)'}}>Upload Post Image</h7>
          </div>:null}
            </div>



                                     <MyEditorS  quillChange={quillChange} editor={editor}/>

        
        <div style={{width:'100%',textAlign:'center',paddingTop:'1vh'}}>
        <Chip onClick={()=>Post()}   label={loadingPost?'Posting':'Create Post'}  className='chip_secondary'/>

        </div>
        </DialogContent>
        }
       
      </Dialog>

   
    <Grid container  >
        <Grid container data-aos="zoom-in" >
            <Grid container  sm={3} xs={12}>

            <Grid item  sm={12} xs={12}>
                <Paper style={{background:'rgba(255,255,255,0.8)',boxShadow:'none',maxWidth:'fit-content',borderRadius:6}}>
                <Typography className='text_location'><span style={{paddingRight:10}}><i class="fas fa-map-marker-alt"></i></span>{location?.city}</Typography>
                </Paper>
            </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>

            <TextField
            style={{background:'rgba(255,255,255,0.8)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold'}}
        id="input-with-icon-textfield"
        // label="TextField"
        placeholder='Search for feed'
value={search}
        onChange={(e)=>Search(e.target.value)}
        
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{color:'var(--blueColor)'}} />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />

            </Grid>
            <Grid style={{textAlign:'-webkit-right'}} item sm={3} xs={12}>
            <Paper style={{background:'rgba(255,255,255,0.8)',cursor:'pointer',boxShadow:'none',maxWidth:'fit-content',borderRadius:6}}>
                <Typography  onClick={()=>Open(true)} className='text_location'> Create a post / event</Typography>
                </Paper>
            </Grid>
           
        </Grid>
       


        
<Grid container  style={{height:'100vh',background:'rgba(5, 17, 33, 0.79)',marginTop:'3vh',padding:10,borderRadius:7}} sm={7} xs={12}>
<Grid item sm={12} xs={12}>
<Typography style={{fontSize:17,fontWeight:'600',padding:'2vh 5px',color:'white'}}>
    {search.length>3&&searchResults&&searchResults[0]?`Recent Feed related to ${search}`:(!search||search.length<=3)?'Recent Feed':`No results for ${search}`}
</Typography>
</Grid>
<Grid container data-aos="fade-up" style={{height:'95%',overflow:'scroll',alignContent:'start'}}>
    {
    (loadingPosts|| loadingLocation?<Box sx={{ display: 'flex' ,justifyContent:'center',paddingTop:'20vh',width:'100%'}}>
    <CircularProgress  style={{height:40,width:40,color:'white'}}/>
  </Box>:
  search.length>3&&searchResults&&searchResults[0]?

  (
    searchResults.map((val,idx)=>
  <Grid key={idx} 
  item sm={12} xs={12}>
  <Card  style={{margin:15}} sx={{ minWidth: 250 }}>
  <CardContent>
  
    <div className='content_people'>

{val.creator.profileImage?<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} src={val.creator.profileImage} ></Avatar>:<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} >{val.creator.fname&&val.creator.lname?(val.creator.fname.charAt(0).toUpperCase()+val.creator.lname.charAt(0).toUpperCase()):val.creator.email.charAt(0).toUpperCase()}</Avatar>}


<div style={{padding:'0px 1vw',width:'40%'}}>
<Typography variant="h6" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)'}} component="div">
{val.creator.fname&&val.creator.lname?(val.creator.fname+' '+val.creator.lname):val.creator.email}
</Typography>
{val.creator.designation?<Typography style={{margin:'2vh 0px'}} variant="h7" sx={{ mb: 1.5 }} >
{val.creator.designation}<b> | </b> {val.creator.company}
</Typography>:null}
<Typography style={{color:'var(--blueColor)',padding:'10px 0px'}} variant="body2">
   <span><i class="fas fa-calendar"></i></span> {dayjs(val.createdAt).format('LL')} · {dayjs(val.createdAt).format('LT')}
      <br />
    </Typography>
</div>
<div style={{width:'50%',textAlign:'right'}}>
{userInfo&&val.creator._id!==userInfo._id?


(
  val.creator.connections&&val.creator.connections[0]&&val.creator.connections.find(val=>val.user===userInfo._id)?
<Chip
className='chip_main'
label="Connected"


onDelete={()=>console.log('')}
deleteIcon={<CheckCircle style={{color:'var(--blueColor)'}} />}
/>:
<Chip
className='chip_main'
label="Connect"

onClick={()=>Connect(val.creator)}
onDelete={()=>Connect(val.creator)}
deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>)
:
userInfo&&val.creator._id===userInfo._id?null
:<Chip
className='chip_main'
label="Connect"
onClick={()=>Connect(val.creator)}
onDelete={()=>Connect(val.creator)}
deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>
}
</div>
</div>
   <Typography style={{display: 'flex',
  alignItems: 'center'}}  variant="h7" color="text.secondary">
    <span  dangerouslySetInnerHTML={{__html:val.post}} className='text_readmore1'></span>
   <b style={{cursor:'pointer'}} onClick={()=>window.location.href=`/post/${val._id}`} >Read more</b>
  
    </Typography>
   { val.postImage?<img src={val.postImage} style={{height:'50vh',width:'100%',objectFit:'contain'}} />
:null}
   <Grid container>
   
   <Grid item sm={6} xs={6}>
    <Typography style={{fontStyle:'italic',fontWeight:'600'}}>
    {val.likes.length<1?null:val.likes.length===1?`1 like ` : `${val.likes.length} likes` }

    </Typography>
  
   </Grid>
   <Grid item sm={6} xs={6}>
   <Typography style={{fontStyle:'italic',fontWeight:'600',textAlign:'end',paddingRight:'1vw'}}>
   {val.comments.length<1?null:val.comments.length===1?`1 Comment ` : `${val.comments.length} Comments` }

    </Typography>
  
  
   </Grid>
   </Grid>
   <Grid container>
     <Grid item sm={12} xs={12} style={{border:'2px solid #eee',margin:'2vh 0px'}}></Grid>
     <Grid item style={{textAlign:'center'}}  sm={6} xs={6}>
    
     {userInfo&&val.likes&&val.likes[0]?
   
   ( val.likes.find(val=>val.user===userInfo._id)? (<Chip
    className='chip_main'
    style={{padding:'0px 1vw'}}
    label="LIKED"
    onDelete={()=>console.log('')}
    deleteIcon={<CheckCircle style={{color:'var(--blueColor)'}} />}
    />):<Chip
    className='chip_secondary'
    style={{padding:'0px 1vw'}}
    label="LIKE"
    onClick={()=>likePost(val)}
    onDelete={()=>likePost(val)}
    deleteIcon={<ThumbUpAltIcon style={{color:'white'}} />}
    /> ) :
     <Chip
    className='chip_secondary'
    style={{padding:'0px 1vw'}}
    label="LIKE"
    onClick={()=>likePost(val)}
    onDelete={()=>likePost(val)}
    deleteIcon={<ThumbUpAltIcon style={{color:'white'}} />}
    />   }
          </Grid>
     <Grid style={{textAlign:'center'}} item sm={6} xs={6}>
     <Chip
    className='chip_main'
    label="COMMENT"

    onClick={()=>Comment(val)}
    onDelete={()=>Comment(val)}
    deleteIcon={<MapsUgcRoundedIcon style={{color:'var(--blueColor)'}} />}
    />  
    
     </Grid>
     {comment&&comment._id===val._id?<><Grid item textAlign={'start'} style={{padding:'1vh 0px',flexDirection:'row',display:'flex'}} sm={12} xs={12}>
  
  
  <TextField value={comment_text} onChange={(e)=>setCommentText(e.target.value)} placeholder='Post your comment here ..' variant={'standard'} multiline   style={{width:'80%',background:'#eee',borderRadius:20,borderColor:'white',padding:10,marginRight:10}} />
  <Chip  onClick={()=>postComment()} label={ loadingPostE?"Posting":'Post Comment'} className='chip_main'  />
  
  </Grid>
  {val.comments&&val.comments[0]?<Grid item textAlign={'start'} style={{maxHeight:'45vh',overflow:'scroll'}} sm={12} xs={12}>
  
  
{val.comments.map((val,idx)=>
  <div key={idx} style={{padding:'2vh 0px',maxWidth:'93%'}} className='content_people'>
{val.user.profileImage?<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} src={val.user.profileImage} ></Avatar>:<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} >{val.user.fname&&val.user.lname?(val.user.fname.charAt(0).toUpperCase()+val.user.lname.charAt(0).toUpperCase()):val.user.email.charAt(0).toUpperCase()}</Avatar>}
 

<div style={{padding:'1vh 1vw',borderRadius:'0px 20px 20px 20px',background:'#eee',marginLeft:10}}>
<Typography variant="h7" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)'}} component="div">
{val.user.fname&&val.user.lname?(val.user.fname+' '+val.user.lname):val.user.email}
  </Typography>
  <Typography color={'text.secondary'} style={{margin:'1vh 0px'}} variant="h8" sx={{ mb: 1.5 }} >
  {val.comment}
  </Typography>

</div>

 </div>)    }
  </Grid>:null}</>:null}

     </Grid>
  
  </CardContent>
  
  </Card>
  </Grid>
  
  
  ))
  :
  (!search||search.length<=3)?
  (


    posts&&posts[0]?
    (
      posts.map((val,idx)=>
    <Grid key={idx} 
    item sm={12} xs={12}>
    <Card  style={{margin:15}} sx={{ minWidth: 250 }}>
    <CardContent>
    
      <div className='content_people'>

{val.creator.profileImage?<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} src={val.creator.profileImage} ></Avatar>:<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} >{val.creator.fname&&val.creator.lname?(val.creator.fname.charAt(0).toUpperCase()+val.creator.lname.charAt(0).toUpperCase()):val.creator.email.charAt(0).toUpperCase()}</Avatar>}


<div style={{padding:'0px 1vw',width:'40%'}}>
<Typography variant="h6" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)'}} component="div">
 {val.creator.fname&&val.creator.lname?(val.creator.fname+' '+val.creator.lname):val.creator.email}
 </Typography>
 {val.creator.designation?<Typography style={{margin:'2vh 0px'}} variant="h7" sx={{ mb: 1.5 }} >
 {val.creator.designation}<b> | </b> {val.creator.company}
 </Typography>:null}
 <Typography style={{color:'var(--blueColor)',padding:'10px 0px'}} variant="body2">
     <span><i class="fas fa-calendar"></i></span> {dayjs(val.createdAt).format('LL')} · {dayjs(val.createdAt).format('LT')}
        <br />
      </Typography>
</div>
<div style={{width:'50%',textAlign:'right'}}>
{userInfo&&val.creator._id!==userInfo._id?


(
  val.creator.connections&&val.creator.connections[0]&&val.creator.connections.find(val=>val.user===userInfo._id)?
<Chip
className='chip_main'
label="Connected"


onDelete={()=>console.log('')}
deleteIcon={<CheckCircle style={{color:'var(--blueColor)'}} />}
/>:
<Chip
className='chip_main'
label="Connect"

onClick={()=>Connect(val.creator)}
onDelete={()=>Connect(val.creator)}
deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>)
:
userInfo&&val.creator._id===userInfo._id?null
:<Chip
className='chip_main'
label="Connect"
onClick={()=>Connect(val.creator)}
onDelete={()=>Connect(val.creator)}
deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>
}
</div>
</div>
     <Typography style={{display: 'flex',
    alignItems: 'center'}}  variant="h7" color="text.secondary">
      <span  dangerouslySetInnerHTML={{__html:val.post}} className='text_readmore1'></span>
     <b style={{cursor:'pointer'}} onClick={()=>window.location.href=`/post/${val._id}`} >Read more</b>
    
      </Typography>
     { val.postImage?<img src={val.postImage} style={{height:'50vh',width:'100%',objectFit:'contain'}} />
:null}
     <Grid container>
     
     <Grid item sm={6} xs={6}>
      <Typography style={{fontStyle:'italic',fontWeight:'600'}}>
      {val.likes.length<1?null:val.likes.length===1?`1 like ` : `${val.likes.length} likes` }
      </Typography>
    
     </Grid>
     <Grid item sm={6} xs={6}>
     <Typography style={{fontStyle:'italic',fontWeight:'600',textAlign:'end',paddingRight:'1vw'}}>
            {val.comments.length<1?null:val.comments.length===1?`1 Comment ` : `${val.comments.length} Comments` }

      </Typography>
    
    
     </Grid>
     </Grid>
     <Grid container>
     <Grid item sm={12} xs={12} style={{border:'2px solid #eee',margin:'2vh 0px'}}></Grid>
     <Grid item style={{textAlign:'center'}}  sm={6} xs={6}>
    
     {userInfo&&val.likes&&val.likes[0]?
   
   ( val.likes.find(val=>val.user===userInfo._id)? (<Chip
    className='chip_main'
    style={{padding:'0px 1vw'}}
    label="LIKED"
    onDelete={()=>console.log('')}
    deleteIcon={<CheckCircle style={{color:'var(--blueColor)'}} />}
    />):<Chip
    className='chip_secondary'
    style={{padding:'0px 1vw'}}
    label="LIKE"
    onClick={()=>likePost(val)}
    onDelete={()=>likePost(val)}
    deleteIcon={<ThumbUpAltIcon style={{color:'white'}} />}
    /> ) :
     <Chip
    className='chip_secondary'
    style={{padding:'0px 1vw'}}
    label="LIKE"
    onClick={()=>likePost(val)}
    onDelete={()=>likePost(val)}
    deleteIcon={<ThumbUpAltIcon style={{color:'white'}} />}
    />   }
          </Grid>
     <Grid style={{textAlign:'center'}} item sm={6} xs={6}>
     <Chip
    className='chip_main'
    label="COMMENT"

    onClick={()=>Comment(val)}
    onDelete={()=>Comment(val)}
    deleteIcon={<MapsUgcRoundedIcon style={{color:'var(--blueColor)'}} />}
    />  
    
     </Grid>
     {comment&&comment._id===val._id?<><Grid item textAlign={'start'} style={{padding:'1vh 0px',flexDirection:'row',display:'flex'}} sm={12} xs={12}>
  
  
  <TextField value={comment_text} onChange={(e)=>setCommentText(e.target.value)} placeholder='Post your comment here ..' variant={'standard'} multiline   style={{width:'80%',background:'#eee',borderRadius:20,borderColor:'white',padding:10,marginRight:10}} />
  <Chip  onClick={()=>postComment()} label={ loadingPostE?"Posting":'Post Comment'} className='chip_main'  />
  
  </Grid>
  {val.comments&&val.comments[0]?<Grid item textAlign={'start'} style={{maxHeight:'45vh',overflow:'scroll'}} sm={12} xs={12}>
  
  
{val.comments.map((val,idx)=>
  <div key={idx} style={{padding:'2vh 0px',maxWidth:'93%'}} className='content_people'>
{val.user.profileImage?<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} src={val.user.profileImage} ></Avatar>:<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} >{val.user.fname&&val.user.lname?(val.user.fname.charAt(0).toUpperCase()+val.user.lname.charAt(0).toUpperCase()):val.user.email.charAt(0).toUpperCase()}</Avatar>}
 

<div style={{padding:'1vh 1vw',borderRadius:'0px 20px 20px 20px',background:'#eee',marginLeft:10}}>
<Typography variant="h7" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)'}} component="div">
{val.user.fname&&val.user.lname?(val.user.fname+' '+val.user.lname):val.user.email}
  </Typography>
  <Typography color={'text.secondary'} style={{margin:'1vh 0px'}} variant="h8" sx={{ mb: 1.5 }} >
  {val.comment}
  </Typography>

</div>

 </div>)    }
  </Grid>:null}</>:null}

     </Grid>
    
    </CardContent>
    
    </Card>
    </Grid>
    
    
    )):<Typography style={{fontSize:'20px',color:'white'}}>
    No Posts found around you
  </Typography>):null
  
  
  
  )  
  }
    
</Grid>

            </Grid>
            <Grid container paddingLeft={'20px'} style={{height:'100vh',marginTop:'3vh'}}  sm={4} xs={12}>
            
            
            
            <Paper style={{padding:'0px 20px',background:'rgba(5, 17, 33, 0.79)',boxShadow:'none',borderRadius:7,width:'100%',height:'100%',overflow:'scroll'}}>
            
            <Grid item sm={12} xs={12}>
<Typography style={{fontSize:17,fontWeight:'600',padding:'2vh 5px',color:'white'}}>
   {'Profiles matching your preferences'}
</Typography>
</Grid>


            {

(loadingUsers||loadingLocation)  ?<Box sx={{ display: 'flex' ,justifyContent:'center',marginTop:'20vh',width:'100%'}}>
<CircularProgress  style={{height:40,width:40,color:'white'}}/>
</Box>:        

            
            
           
((userInfo?(users.filter(val=>(val._id!==userInfo._id))):users).map((val,idx)=>

  <Grid key={idx} 
  item sm={12} xs={12}>
  <Card  style={{margin:15,cursor:'pointer'}}  onClick={()=>window.location.href=`/people/${val._id}`} sx={{ minWidth: 250 }}>
  <CardContent>
   <div className='content_people'>
   {val.profileImage?<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} src={val.profileImage} ></Avatar>:<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} >{val.fname&&val.lname?(val.fname.charAt(0).toUpperCase()+val.lname.charAt(0).toUpperCase()):val.email.charAt(0).toUpperCase()}</Avatar>}
   
  
  <div style={{padding:'0px 1vw'}}>
  <Typography variant="h6" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)',wordBreak:'break-word'}} component="div">
  {val.fname&&val.lname?(val.fname+' '+val.lname):val.email}
    </Typography>
    {val.designation?<Typography style={{margin:'2vh 0px'}} variant="h7" sx={{ mb: 1.5 }} >
      {val.designation}<b> | </b> {val.company}
      </Typography>:null}
    <div >
    <div style={{width:'100%'}}>
{userInfo&&val._id!==userInfo._id?


(
  val.connections&&val.connections[0]&&val.connections.find(val=>val.user===userInfo._id)?
<Chip
className='chip_main'
label="Connected"


onDelete={()=>console.log('')}
deleteIcon={<CheckCircle style={{color:'var(--blueColor)'}} />}
/>:
<Chip
className='chip_main'
label="Connect"

onClick={()=>Connect(val)}
onDelete={()=>Connect(val)}
deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>)
:
userInfo&&val._id===userInfo._id?null
:<Chip
className='chip_main'
label="Connect"
onClick={()=>Connect(val)}
onDelete={()=>Connect(val)}
deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/>
}
</div>
  </div>
  </div>
  
   </div>
    {/* <br /><br /> */}
  
  
  
  </CardContent>
  
  </Card>
  </Grid>
    ))}
    </Paper>
                </Grid>
    </Grid>
</Container>


    </div>
  )
}
