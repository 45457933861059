import { UPLOAD_URL, USERS_URL } from "../constants";
import { apiSlice } from "./apiSlice";
export const usersApiSlice=apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        login:builder.mutation({
            query:(data)=>({
                url:`${USERS_URL}/auth`,
                method:'POST',
                body:data

            })

        }),
        register:builder.mutation({


            query:(data)=>({

                
                url:`${USERS_URL}/auth`,
                method:'PUT',
                body:data

            })

        }),
        verifyUser:builder.mutation({
            query:(data)=>({
                url:`${USERS_URL}/verify`,
                method:'PUT',
                body:data

            })

        }),
        forgotPassword:builder.mutation({
            query:(data)=>({
                url:`${USERS_URL}/forgotPassword`,
                method:'POST',
                body:data

            })
        }),
       
        verifyForgot:builder.mutation({
            query:(data)=>({
                url:`${USERS_URL}/verify`,
                method:'POST',
                body:data

            })

        }),
        changePassword:builder.mutation({
            query:(data)=>({
                url:`${USERS_URL}/forgotPassword`,
                method:'PUT',
                body:data

            })
        }),

        fetchProfile:builder.query({
            query:(id)=>(
                id?
                {
                url:`${USERS_URL}/${id}`


            }:null),
            keepUnusedDataFor:5,

        }),


        editProfile:builder.mutation(
            

            {


            query:(data)=>(
                {


                url:`${USERS_URL}/${data._id}`,
                method:'PUT',
                body:data,



            }),
            

        }
        
        )
     ,
        uploadImage:builder.mutation(
            

            {


            query:(data)=>(
                {


                url:`${UPLOAD_URL}/img`,
                method:'POST',
                body:data,



            }),
            

        }
        
        )
,

        fetchAllProfiles:builder.query({
            query:(id)=>({
                url:`${USERS_URL}/user`
    
    
            }),
            keepUnusedDataFor:5,
    
        }),

        logoutUser:builder.mutation(
            

            {


            query:()=>(
                {


                url:`${USERS_URL}/logout`,
                method:'POST',



            }),
            

        }
        
        ),

        helpEmail:builder.mutation(
            

            {
    
    
            query:(data)=>(
                {
    
    
                url:`${USERS_URL}/forms`,
                method:'POST',
                body:data
    
    
    
            }),
            
    
        }
        
        ),
       fundEmail:builder.mutation(
            

            {
    
    
            query:(data)=>(
                {
    
    
                url:`${USERS_URL}/forms`,
                method:'PUT',
                body:data
    
    
    
            }),
            
    
        }
        
        )
        ,forInvestor:builder.mutation(
            

            {
    
    
            query:(data)=>(
                {
    
    
                url:`${USERS_URL}/forInvestor`,
                method:'POST',
                body:data
    
    
    
            }),
            
    
        }
        
        )


    }),
   


  
})

export const {useLoginMutation,useRegisterMutation,useVerifyUserMutation,useForgotPasswordMutation,
    useVerifyForgotMutation,useChangePasswordMutation,useFetchProfileQuery,useEditProfileMutation,useUploadImageMutation,useFetchAllProfilesQuery,useLogoutUserMutation,useHelpEmailMutation,useFundEmailMutation,useForInvestorMutation}=usersApiSlice