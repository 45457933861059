import React, { useEffect, useState } from 'react'
// import './p_people.css'
import { useParams } from 'react-router-dom';
import { Avatar, Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, Container, Grid, InputAdornment, Paper, Tab, Tabs, TextField, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MessageIcon from '@mui/icons-material/Message';
import { CheckCircle, IosShareOutlined,Share, ThumbUpRounded } from '@mui/icons-material';
import './event.css'

import { useEditEventMutation, useFetchAllEventsQuery, useFetchParticularEventQuery } from '../../slices/eventSlice';
import dayjs from 'dayjs';
import EventIcon from '@mui/icons-material/Event';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
export default function Event() {
  const {userInfo}=useSelector((state)=>state.auth)

  let {id}=useParams() 
  const [value,setValue]=useState(0)
  const arr=[1,2,3,4,5,6,1,2,3,4,5,6]
  const {data:event,isLoading,isError,refetch}=useFetchParticularEventQuery(id);
  const {data:events,isLoading:loadingEvents,isError:errorEvent}=useFetchAllEventsQuery()
  const [comment_text,setCommentText]=useState('')
  const [editEvent,{isLoading:loadingEventE}]=useEditEventMutation()

  useEffect(() => {
    if(isError)   
         window.location.href='/people'
       }, [isLoading])
       const copyUrl=()=>{
        const el = document.createElement('textarea');
        el.value =`https://thefounderworld.com/event/${event._id}`;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      toast.success('Event url copied successfully')
      }

       const likePost=async(val)=>{
        if(userInfo){
          let like={
            _id:val._id,
            like:
           { 
            id:userInfo._id}
          }
        
        try {
          
        const res=await editEvent(like)
        if(res){
          refetch()
        }
        
        } catch (error) {
           toast.error(error?.data?.message||error.error)
        
          
        }}
        else{
          toast.error('Please sign in to continue ..')
        }
          // refetch()
         }





       const postComment=async()=>{
        
        
        if(comment_text){
      if(userInfo){
        let comment1={
          _id:event._id,
          comment:
         { 
          id:userInfo._id,
          comment:comment_text
        }
        }
      
      try {
        
      const res=await editEvent(comment1)
      if(res){
      
        setCommentText('')
        refetch()
      }
      
      } catch (error) {
         toast.error(error?.data?.message||error.error)
      
        
      }}
      else{
        toast.error('Please sign in to continue ..')
      }
      
        }
      } 
  return (

    <div style={{width:'100%',background:'#eee',minHeight:'110vh'}}>
    <Container style={{paddingTop:110}}>
       {event&&!isLoading&&!loadingEvents?<Grid container>
            <Grid container style={{marginBottom:'5vh'}} sm={8} xs={12}>
                <Paper style={{minHeight:'40vh',width:'100%',borderRadius:15}}>
                    <img   src={event.eventImage?event.eventImage:require('../images/people.jpg')} style={{width:'100%',height:'50vh',objectFit:'cover',borderTopLeftRadius:15,borderTopRightRadius:15,}} />
                    {/* <Avatar  sx={{height:120,width:120,border:'3px solid white',marginTop:'-80px',marginLeft:'1vw'}} alt='avatar' src={require('../images/trust.png')}></Avatar> */}


<Grid container style={{padding:'0px 1.5rem'}}> 

<Grid  style={{padding:'1vh 0px 0px 0px'}}item sm={12} xs={12}>
    <Typography variant='h5' style={{fontWeight:'bold',color:'var(--blueColor)'}} >{event.name}</Typography>
</Grid>
<Grid  style={{padding:'1vh 0px 0px 0px',maxWidth:'80%'}}item sm={12} xs={12}>
    <Typography variant='h8'  >Event by <b>{event.creator.fname+' '+event.creator.lname}</b></Typography>
</Grid>
<Grid  style={{padding:'1vh 0px 0px 0px',maxWidth:'80%'}}item sm={12} xs={12}>
    <Typography  variant='h8'  >     <span><i class="fas fa-calendar"></i></span> {dayjs(event.startDate).format('LL')} · {dayjs(event.startTime).format('LT')} {event.endDate?(" - "):''}{dayjs(event.endDate).format('LL')} · {dayjs(event.endTime).format('LT')}
</Typography>
</Grid>
<Grid  style={{padding:'1vh 0px 0px 0px',maxWidth:'80%'}}item sm={12} xs={12}>
    <Typography  variant='h8' style={{fontWeight:'500',color:'var(--blueColor)'}} ><span style={{paddingRight:10}}><i class={`fas ${event.eventType==="Online"?'fa-video':'fa-map-marker'}`}></i></span> {event.eventType} </Typography>
</Grid>
{event.likes.length<1?null:
<Grid  style={{padding:'1vh 0px 0px 0px',maxWidth:'80%'}}item sm={12} xs={12}>
    <Typography  variant='h9' style={{fontWeight:'500',color:'var(--blueColor)'}} ><i class="fas fa-user-circle"></i>    {event.likes.length<1?null:event.likes.length===1?`1 interest ` : `${event.likes.length} interests` }
 </Typography>
</Grid>}


<Grid item style={{textAlign:'start',padding:'2vh 0px'}}  sm={12} xs={12}>
{userInfo&&event.likes&&event.likes[0]?
   
   ( event.likes.find(val=>val.user===userInfo._id)? (<Chip
    className='chip_main'
    style={{padding:'0px 1vw'}}
    label="INTERESTED"
    onDelete={()=>console.log('')}
    deleteIcon={<CheckCircle style={{color:'var(--blueColor)'}} />}
    />):<Chip
    className='chip_secondary'
    style={{padding:'0px 1vw'}}
    label="LIKE"
    onClick={()=>likePost(event)}
    onDelete={()=>likePost(event)}
    deleteIcon={<ThumbUpRounded style={{color:'white'}} />}
    /> ) :
     <Chip
    className='chip_secondary'
    style={{padding:'0px 1vw'}}
    label="LIKE"
    onClick={()=>likePost(event)}
    onDelete={()=>likePost(event)}
    deleteIcon={<ThumbUpRounded style={{color:'white'}} />}
    />   } 
    
     <Chip
className='chip_main'
style={{padding:'0px 1vw'}}
  label="Share"
  onClick={()=>copyUrl()}

onDelete={()=>copyUrl()}
  deleteIcon={<Share style={{color:'var(--blueColor)'}} />}
/>    
{event.externalLink?<Chip
className='chip_main'
style={{padding:'0px 1vw'}}
  label="Join"
  onClick={()=>window.open(event.externalLink)}

onDelete={()=>window.open(event.externalLink)}
  deleteIcon={<EventIcon style={{color:'var(--blueColor)'}} />}
/> :null }





 </Grid>
 <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value}    aria-label="basic tabs example">
          <Tab  onClick={()=>setValue(0)} label="Details"  />
          <Tab onClick={()=>setValue(1)} label={`Comments ${event?.comments&&event.comments[0]?(` (${event.comments.length}) `):''}`} />

        </Tabs>
        <Grid container style={{padding:'0px 1.5rem'}}> 
                    
                    
                
         {  value===0? <Grid style={{padding:'2vh 0px',maxHeight:'40vh',overflow:'scroll'}} item sm={12} xs={12}>

         {event.address?
         <>
                    <Typography variant='h9' style={{fontWeight:'lighter',fontSize:'1.2rem',lineHeight:1.7}}>
                    <b>Address</b>  : {event.address}
                    
                    </Typography><br /></>:null}
                    {event.venue?
  <>
 <Typography variant='h9' style={{fontWeight:'lighter',fontSize:'1.2rem',lineHeight:1.7}}>
                    <b>Venue</b>  : {event.venue}</Typography><br /></>:null}
                   
             
                    <Typography variant='h9' style={{fontWeight:'lighter',fontSize:'1.2rem',lineHeight:1.7}}>
                   {event.description}
                    
                    </Typography>

                    <Grid style={{flexWrap:'wrap'}} container  sm={12} xs={12}>
{event.tags&&event.tags.map((val,idx)=>
 <Chip  key={idx}  label={val}  className='chip_main'/>
)        
}  </Grid>  
                    </Grid>:
                    <Grid style={{padding:'2vh 0px',maxHeight:'40vh',overflow:'scroll'}} item sm={12} xs={12}>



<Grid item textAlign={'start'} style={{padding:'1vh 0px',flexDirection:'row',display:'flex'}} sm={12} xs={12}>
  
  
  <TextField value={comment_text} onChange={(e)=>setCommentText(e.target.value)} placeholder='Post your comment here ..' variant={'standard'} multiline   style={{width:'80%',background:'#eee',borderRadius:20,borderColor:'white',padding:10,marginRight:10}} />
  <Chip  onClick={()=>postComment()} label={ loadingEventE?"Posting":'Post Comment'} className='chip_main'  />
  
  </Grid>
  {event.comments&&event.comments[0]?<Grid item textAlign={'start'} style={{maxHeight:'45vh',overflow:'scroll'}} sm={12} xs={12}>
  
  
{event.comments.map((val,idx)=>
  <div key={idx} style={{padding:'2vh 0px',maxWidth:'93%'}} className='content_people'>
{val.user.profileImage?<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} src={val.user.profileImage} ></Avatar>:<Avatar sx={{ width: 50, height: 50 }} style={{backgroundColor:'var(--blueColor)',color:'white'}} >{val.user.fname&&val.user.lname?(val.user.fname.charAt(0).toUpperCase()+val.user.lname.charAt(0).toUpperCase()):val.user.email.charAt(0).toUpperCase()}</Avatar>}
 

<div style={{padding:'1vh 1vw',borderRadius:'0px 20px 20px 20px',background:'#eee',marginLeft:10}}>
<Typography variant="h7" style={{fontWeight:'600',paddingBottom:'1vh',color:'var(--blueColor)'}} component="div">
{val.user.fname&&val.user.lname?(val.user.fname+' '+val.user.lname):val.user.email}
  </Typography>
  <Typography color={'text.secondary'} style={{margin:'1vh 0px'}} variant="h8" sx={{ mb: 1.5 }} >
  {val.comment}
  </Typography>

</div>

 </div>)    }
  </Grid>:null}

</Grid>}
                    
                    </Grid>
      </Box>
      </Box>
</Grid>



                </Paper>
              
            
            </Grid>
            <Grid container style={{padding:'0px 20px'}} sm={4} xs={12}>
            <Paper style={{padding:'0px 10px',background:'rgba(5, 17, 33, 0.79)',boxShadow:'none',borderRadius:7,width:'100%',maxHeight:'100vh',overflow:'scroll'}}>
            
            <Grid item sm={12} xs={12}>
<Typography style={{fontSize:17,fontWeight:'600',padding:'2vh 5px',color:'white'}}>
   More Events
</Typography>
</Grid>
            {(userInfo?(events.filter(val=>(val._id!==event._id))):events).map((val,idx)=>

<Grid key={idx} 
item sm={12} xs={12}>
<Card onClick={()=>window.location.href=`/event/${val._id}`}  style={{margin:15,cursor:'pointer'}} sx={{ minWidth: 250 }}>
<CardContent>
 <div className='content_people'>
<div>

   <img src={val.eventImage} style={{height:100,width:100,objectFit:'contain',border:'1px solid #eee',borderRadius:'10px',padding:5}} /> 
    </div> 

<div style={{padding:'0px 10px'}}>

<Grid  style={{padding:'1vh 0px 1vh 0px'}}item sm={12} xs={12}>
<b><i class="fas fa-calendar"></i></b>  {dayjs(val.startDate).format('LL')} · {dayjs(val.startTime).format('LT')} {val.endDate?(" - "):''}{dayjs(val.endDate).format('LL')} · {dayjs(val.endTime).format('LT')}
</Grid> 
<Typography variant="h7" style={{fontWeight:'600',color:'var(--blueColor)'}} component="div">
{val.name}
 </Typography>
  <Typography style={{margin:'2vh 0px',fontSize:15}} variant="h8" sx={{ mb: 1.5 }} >
  Event by <b>{val.creator.fname +' '+val.creator.lname }</b>
  </Typography>
  <div >
{/* <Chip
className='chip_main'
label="Connect"
onDelete={()=>console.log('')}
deleteIcon={<AddCircleIcon style={{color:'var(--blueColor)'}} />}
/> */}
</div>
</div>

 </div>
  {/* <br /><br /> */}

  {/* <Typography style={{margin:'2vh 0px'}} variant="h7" sx={{ mb: 1.5 }} color="text.secondary">
  Hello Everyone💕
We're happy to invite you to the ✨"Nav Grah Awakening" . 
  </Typography> */}

</CardContent>

</Card>
</Grid>
  )}
    </Paper>
            </Grid>
        </Grid>:<Box sx={{ display: 'flex' ,justifyContent:'center',marginTop:'20vh'}}>
      <CircularProgress  style={{height:50,width:50}}/>
    </Box>}
    </Container>
    </div>
  )
}
