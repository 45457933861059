import { Container} from '@mui/material'
import React from 'react'
import './footer.css'

const Footer = () => {
    return (
        <div className="Footer_Items_Block">
        <Container className="Footer_Items">
            
      
           
            <div className="Footer_Items_P_Div">  
                <p className="Footer_Items_P">
                    &#169;2023 The Founder World <br />Product By BrenStone International Pvt. Ltd.
                </p>
            </div>
        </Container>
    </div>
    )
}

export default Footer
