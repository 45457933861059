import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/common/header/navbar';
import Home from './components/home/home';
import Footer from './components/common/footer/footer';
import Signin from './components/Signin/signin';
import People from './components/people/people';
import Help from './components/help/help';
import Particular_people from './components/people_particular/p_people';
import ContactUS from './components/contactus/contactus';
import ScrollT from './components/common/STTP/scroll_Top';
import Event from './components/event/event';
import Post from './components/post/post';
import Profile from './components/dashboard/profile';
import NeedFunds from './components/contactus/funds';
import Chat from './components/common/chat/chat';
import ForInvestors from './components/contactus/forInvestors';

function App() {

  return (
    <BrowserRouter>
    <Navbar />
    <Chat />
    <ScrollT />
    <Routes>
    <Route  path='/' element={<Home />} />
    <Route  path='/signin' element={<Signin />} />

    <Route  path='/people' element={<People />} />
    <Route  path='/people/:id' element={<Particular_people />} />
    <Route  path='/event/:id' element={<Event />} />
    <Route  path='/post/:id' element={<Post />} />


    <Route  path='/help' element={<ContactUS />} />
    <Route  path='/looking-for-funds' element={<NeedFunds />} />

    <Route  path='/be-an-investor' element={<ForInvestors />} />

    <Route  path='/profile' element={<Profile />} />

    </Routes>

    <Footer />
    <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
