import { Chip, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import './home_top.css'
import Aos from 'aos';
import 'aos/dist/aos.css'; 
export default function Home_top() {



  // console.log(('content://com.android.externalstorage.documents/tree/primary%3ADownload%2FFilly/document/primary%3ADownload%2FFilly%2F.trashed-1696511278-FillyMediaDOC_Filly%20(2).pdf').replace('content://com.android.externalstorage.documents/tree/primary%3ADownload%2FFilly/document/primary%3ADownload%2FFilly%2F.trashed-','').split('-')[1])
  useEffect(() => {
    Aos.init({ duration: 1000 });
}, []);

  return (

    <Grid container justifyContent={'center'} >
    <Grid item sm={12} xs={12}   className='blog_bgC'> 
    <video  data-aos="fade-in" autoPlay className='video_main'   loop preload="none" muted playsInline id="video2">
    <source src={require('../images/main.mp4')}  type="video/mp4" />
    </video>
   <div  class="max-width">
    <div data-aos="fade-up"
     data-aos-duration="3000"  class="crousel-content">
      <h2>Aspire. Grow. Learn</h2>
      <p data-aos="fade-up"
     data-aos-duration="3000" >
      Expand Your Network
      <p  data-aos="fade-up"
     data-aos-duration="3000" style={{marginTop:'15px'}}><span>Learn from Experience</span> </p>
      </p>
      <p data-aos="fade-up"
     data-aos-duration="3000"  class="sub-label">Connect with entrepreneurs, cofounders, mentors, and investors from diverse fields to broaden your opportunities from a wide community of passionate founders.</p>
 
    </div>
  </div>
    </Grid>
    <Grid container style={{alignItems:'center',maxWidth:1200,padding:'5vh 0px'}}>
      <Grid container sm={6} xs={12}>
        <Grid item sm={12} xs={12}>
<Typography  data-aos="zoom-in" className='home_head1'>
The people platform—Where interests become friendships
</Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
        <Typography data-aos="zoom-in" className='home_detail'>
        Whatever your interest, from hiking and reading to networking and skill sharing, there are thousands of people who share it on The Founder World. Events are happening every day—sign up to join the fun.
</Typography>
          </Grid>
          <Grid   item sm={12} xs={12}>
          <Chip onClick={()=>window.location.href='/people'} label={'Create Event'} style={{background:'var(--blueColor)',color:'white',fontWeight:600,margin:'1vh 0px'}} />
          </Grid>
      </Grid>
      <Grid item sm={6} style={{textAlignLast:'center'}} xs={12}>
        <img data-aos="zoom-in" src={require('../images/online_events.svg').default} alt='Events' style={{height:300,width:'80%'}} />
      </Grid>
    </Grid>
    <Grid container style={{alignItems:'center',maxWidth:1200,padding:'5vh 0px'}}>
  
      <Grid item sm={6} style={{textAlignLast:'center'}} xs={12}>
        <img data-aos="zoom-in" src={require('../images/people-img.webp')} alt='People' style={{height:300,width:'80%'}} />
      </Grid>
      <Grid container style={{background:'var(--blueColor)',borderRadius:25,padding:20}} sm={6} xs={12}>
        <Grid item sm={12} xs={12}>
<Typography style={{color:'white'}}  data-aos="zoom-in" className='home_head1'>
Build your diverse People Stack
</Typography>



        </Grid>
        <Grid item sm={12} xs={12}>
        <Typography style={{color:'white'}}  data-aos="zoom-in" className='home_detail'>
        Surround yourself with amazing people to achieve great things
</Typography>
<Typography  style={{display:'flex',alignItems:'center',padding:10,color:'white'}} data-aos="zoom-in" className='home_detail'>
      <span className='span_head'></span>  Meet your potential co-founder
</Typography>
<Typography style={{display:'flex',alignItems:'center',padding:10,color:'white'}} data-aos="zoom-in" className='home_detail'>
      <span className='span_head'></span>Get your investment
</Typography>
<Typography style={{display:'flex',alignItems:'center',padding:10,color:'white'}} data-aos="zoom-in" className='home_detail'>
      <span className='span_head'></span> Leverage from Industry Experts
</Typography>
          </Grid>
          <Grid   item sm={12} xs={12}>
          <Chip label={'Find People'} onClick={()=>window.location.href='/people'} style={{color:'var(--blueColor)',background:'white',fontWeight:600,marginTop:'1vh'}} />
          </Grid>
      </Grid>
    </Grid>


    <Grid container style={{alignItems:'center',maxWidth:1200,padding:'5vh 0px'}}>
      <Grid container sm={6} xs={12}>
        <Grid item sm={12} xs={12}>
<Typography  data-aos="zoom-in" className='home_head1'>
Grow your
Trusted Network 
of professionals</Typography>

        </Grid>
        <Grid item style={{textAlignLast:'center',padding:'1vh 0px'}}  sm={12} xs={12}>
        <img data-aos="zoom-in" src={require('../images/trust.png')} alt='Events' style={{height:100,width:100}} />

        <Typography style={{padding:'1vh 0px',fontWeight:'600'}} data-aos="zoom-in" className='home_detail'>
        Your Trusted Network Of Professionals
</Typography>
<Typography style={{padding:'2vh 0px',textAlignLast:'left'}} data-aos="zoom-in" className='home_detail'>
Create your own network to help you succeed. Make better decisions. Get advice, collaborate, raise capital, and move up. We are on a mission to make the founder world of professionals available to everyone.</Typography>
          </Grid>
          <Grid   item sm={12} xs={12}>
          <Chip label={'Find Cofounders'} onClick={()=>window.location.href='/people'} style={{background:'var(--blueColor)',color:'white',fontWeight:600}} />
          </Grid>
      </Grid>
      <Grid item sm={6} style={{textAlignLast:'center'}} xs={12}>
      <img data-aos="zoom-in" src={require('../images/people.png')} alt='Events' style={{height:300,width:'80%',objectFit:'contain',padding:'1vh 0px'}} />
      </Grid>
    </Grid>

  </Grid>
         )
}